/* eslint-disable no-multi-assign */ // ok in reducers
import * as types from 'constants/action_types'
import * as pages from 'constants/pages'

import { DEFAULT_SORT_ORDER } from 'constants/defaults'

import deepEqual from 'fast-deep-equal'
import { diff, isEmpty, emptyArr } from 'util/arrays'

import { isMobile } from 'util/media_query'
import { SNOOZED_INDEFINITELY, snoozeOptions } from 'util/snooze'

// HACK (jscheel): Part of doMarkAsRead hack below.
import { UPDATE_PREFERENCES_SUCCESS } from 'ducks/currentUser/types'
import { BULK_UPDATE_CONVERSATIONS_STARTED } from 'ducks/tickets/actionTypes'
// END HACK

const defaultState = {
  isBulkSelectionMode: false,
  isMerging: false,
  optimisticMergeTicketsById: {},
  previousSortBy: DEFAULT_SORT_ORDER,
  selected: [],
  showMergeSnippets: false,
  snippetsById: {},
  sortBy: DEFAULT_SORT_ORDER,
  gcCandidates: emptyArr,
  lastSnoozedDate: null,
}

const reducers = {}

reducers[types.MARK_BULK_SELECTION_MODE] = (state, { data }) => {
  const { selected } = state
  const { ticketIds } = data

  return Object.assign({}, state, {
    isBulkSelectionMode: true,
    selected: ticketIds.length === 1 ? ticketIds : selected,
  })
}

const deselectAll = state => {
  return {
    ...state,
    selected: [],
    isBulkSelectionMode: false,
    selectedPivotId: null,
    selectedRangeEndId: null,
  }
}

reducers[types.UNMARK_BULK_SELECTION_MODE] = reducers[
  types.ASSIGN_TICKETS_TO_AGENT
] = deselectAll

reducers[types.TOGGLE_TICKET_SELECTION] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (isTicketSelected) {
    const withoutTicket = selected.filter(selectedId => selectedId !== id)
    const isNowEmpty = isEmpty(withoutTicket)
    return {
      ...state,
      isBulkSelectionMode: !isNowEmpty,
      selected: withoutTicket,
      selectedPivotId: isNowEmpty ? null : state.selectedPivotId,
      selectedRangeEndId: isNowEmpty ? null : state.selectedRangeEndId,
    }
  }

  return {
    ...state,
    isBulkSelectionMode: true,
    selected: [...selected, id],
    selectedPivotId: id,
    selectedRangeEndId: null,
  }
}

const getRange = (arr, startIndex, endIndex) => {
  if (startIndex < endIndex) {
    return arr.slice(startIndex + 1, endIndex + 1)
  }
  return arr.slice(endIndex, startIndex).reverse()
}

reducers[types.RANGED_TICKET_SELECTION] = (state, action) => {
  const { selected } = state
  const { id, pivotId, rangeEndId, sortedMailboxTicketIds } = action.data
  const pivotIndex = sortedMailboxTicketIds.indexOf(pivotId)
  const rangeEndIndex = sortedMailboxTicketIds.indexOf(rangeEndId)
  const newEndIndex = sortedMailboxTicketIds.indexOf(id)

  // clear the old range
  const oldRange = getRange(sortedMailboxTicketIds, pivotIndex, rangeEndIndex)
  const newRange = getRange(sortedMailboxTicketIds, pivotIndex, newEndIndex)

  return {
    ...state,
    selected: diff(selected, oldRange).concat(newRange),
    selectedRangeEndId: id,
  }
}

reducers[types.SELECT_TICKET] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (isTicketSelected) {
    return state
  }
  return Object.assign({}, state, {
    selected: [...selected, id],
  })
}

reducers[types.DESELECT_TICKET] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (!isTicketSelected) {
    return state
  }
  return Object.assign({}, state, {
    selected: selected.filter(selectedId => selectedId !== id),
  })
}

reducers[types.DESELECT_ALL_TICKETS] = state => {
  return Object.assign({}, state, {
    selected: [],
  })
}

function unique(value, index, self) {
  return self.indexOf(value) === index
}

reducers[types.SELECT_TICKETS] = (state, action) => {
  const { selected } = state
  const { ticketIds } = action.data
  let newSelected = selected || []
  newSelected = newSelected.concat(ticketIds || [])
  newSelected = newSelected.filter(unique)
  return Object.assign({}, state, {
    selected: newSelected,
    isBulkSelectionMode: true,
  })
}

reducers[types.TOGGLE_TICKETS_SORTING] = state => {
  const { sorting } = state

  if (sorting) {
    return Object.assign({}, state, {
      sorting: false,
    })
  }
  return Object.assign({}, state, {
    sorting: true,
  })
}

reducers[pages.TICKET_PAGE] = state => {
  // on desktop, its the same as a reply page
  if (!isMobile()) return state

  // on mobile, we have various janky jankness...
  return {
    ...state,
    // jank. isReplying already available in the route/location but desktop
    // doesnt use it (yet!)
    isReplying: false,
    isMerging: false, // FIXME - comes from page props now
  }
}

reducers[pages.TICKET_REPLY_PAGE] = reducers[
  pages.TICKET_REPLY_CHANGESET_PAGE
] = reducers[pages.TICKET_REPLY_DIRECT_PAGE] = reducers[
  pages.TICKET_REPLY_DIRECT_CHANGESET_PAGE
] = reducers[pages.TICKET_FORWARD_CHANGESET_PAGE] = reducers[
  pages.TICKET_FORWARD_PAGE
] = reducers[pages.TICKET_FORWARD_PAGE] = state => {
  return {
    ...state,
    isMerging: false, // DEPRECATED: comes from page props now
  }
}

function clearSelected(state) {
  return {
    ...state,
    isBulkSelectionMode: false,
    selected: [],
    selectedPivotId: null,
    selectedRangeEndId: null,
  }
}

reducers[pages.FOLDER_PAGE] = reducers[pages.MAILBOX_FOLDER_PAGE] = reducers[
  pages.MAILBOX_PAGE
] = reducers[pages.MAIN_PAGE] = reducers[pages.SEARCH_PAGE] = reducers[
  pages.TICKETS_PAGE
] = reducers[pages.NEW_CONVERSATION_PAGE] = reducers[
  pages.LOG_CONVERSATION_PAGE
] = (state, action) => {
  const {
    type,
    payload,
    meta: {
      query: { folder, channel, orderBy } = {},
      location: {
        prev: {
          type: prevType,
          payload: prevPayload,
          query: {
            folder: prevFolder,
            channel: prevChannel,
            orderBy: prevOrderBy,
          } = {},
        } = {},
      } = {},
    } = {},
  } = action
  if (
    type !== prevType ||
    folder !== prevFolder ||
    channel !== prevChannel ||
    orderBy !== prevOrderBy ||
    !deepEqual(payload, prevPayload)
  ) {
    // only clear selected if there was an actual page/type change
    return clearSelected({
      ...state,
      // jank. isReplying already available in the route/location but desktop
      // doesnt use it (yet!)
      isReplying: false,
    })
  }

  return state
}

reducers[types.TOGGLE_SHOW_CC_BCC] = state => {
  const { showCcBcc } = state

  if (showCcBcc) {
    return Object.assign({}, state, {
      showCcBcc: false,
    })
  }
  return Object.assign({}, state, {
    showCcBcc: true,
  })
}

reducers[types.UPDATE_NOTE_FORM_HEIGHT] = (state, action) => {
  const newState = {}
  const { height } = action.data
  newState.noteFormHeight = height
  return Object.assign({}, state, newState)
}

reducers[types.FETCH_TICKET_SNIPPETS_SUCCESS] = (state, action) => {
  const { lastComments } = action.data
  if (!lastComments) return state
  const newSnippetsById = Object.assign({}, state.snippetsById || {})
  lastComments.map(snippet => {
    newSnippetsById[snippet.ticketId] = snippet.body
    return null
  })
  return Object.assign({}, state, {
    snippetsById: newSnippetsById,
  })
}

reducers[types.UPDATE_MERGE_SNIPPETS_VISIBILITY] = (state, action) => {
  const { visible } = action.data
  return Object.assign({}, state, {
    showMergeSnippets: visible,
  })
}

reducers[BULK_UPDATE_CONVERSATIONS_STARTED] = state => {
  return deselectAll(state)
}

reducers[types.SAVE_LAST_SNOOZED_DATE] = (
  state,
  { data: { lastSnoozedDate } }
) => {
  // only saving custom snooze time that doesn't match the available predefined options
  if (!lastSnoozedDate || !(lastSnoozedDate instanceof Date)) {
    return {
      ...state,
      lastSnoozedDate: null,
    }
  }

  const predefinedSnoozeTimes = snoozeOptions()
    .filter(o => o.showOption() && o.value !== SNOOZED_INDEFINITELY)
    .map(o => o.timestamp())

  if (predefinedSnoozeTimes.includes(lastSnoozedDate.getTime())) {
    return {
      ...state,
      lastSnoozedDate: null,
    }
  }

  return {
    ...state,
    lastSnoozedDate: lastSnoozedDate.getTime(),
  }
}

reducers[types.UPDATE_APP_DATA] = (state, action) => {
  const { currentUser } = action.data
  if (!currentUser) return state
  const prefs = currentUser.preferences
  if (!prefs) return state
  return {
    defaultReplyState: prefs.defaultReplyState,
    reassignTicketOnReply: prefs.reassign_ticket_on_reply,
    reassignTicketOnNote: prefs.reassign_ticket_on_note,
    currentUserId: currentUser.id,
    ...state,
  }
}

reducers[UPDATE_PREFERENCES_SUCCESS] = (state, { payload }) => {
  return {
    ...state,
    reassignTicketOnReply: payload.reassign_ticket_on_reply,
    reassignTicketOnNote: payload.reassign_ticket_on_note,
  }
}

reducers[types.UPDATE_ACCOUNT_SUCCESS] = (state, { payload }) => {
  const newDefaultReplyState = payload?.updateAccount?.preferences?.reply_button

  if (typeof newDefaultReplyState !== 'boolean') {
    return state
  }

  return {
    ...state,
    defaultReplyState: newDefaultReplyState,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
