import {
  HIDE_MODAL,
  INTEGRATIONS_INTEGRATION_SELECTED,
  INTEGRATIONS_METHOD_CHANGED,
  INTEGRATIONS_UPDATE_FORM_VALUE,
  INTEGRATION_FETCH_GITHUB_REPOS_REQUEST,
  INTEGRATION_FETCH_GITHUB_REPOS_SUCCESS,
  INTEGRATION_FETCH_GITHUB_REPOS_FAILURE,
  INTEGRATION_FETCH_GITHUB_ISSUES_REQUEST,
  INTEGRATION_FETCH_GITHUB_ISSUES_SUCCESS,
  INTEGRATION_FETCH_GITHUB_ISSUES_FAILURE,
  INTEGRATION_FETCH_GITHUB_REPO_DETAILS_REQUEST,
  INTEGRATION_FETCH_GITHUB_REPO_DETAILS_SUCCESS,
  INTEGRATION_FETCH_GITHUB_REPO_DETAILS_FAILURE,
  INTEGRATIONS_CLEAN_FORM_VALUES,
  INTEGRATION_SET_NEXT_STEP_DEPENDENCY_FIELDS,
  INTEGRATION_CREATE_GITHUB_ISSUE_REQUEST,
  INTEGRATION_CREATE_GITHUB_ISSUE_SUCCESS,
  INTEGRATION_CREATE_GITHUB_ISSUE_FAILURE,
  INTEGRATION_FORM_SET_REQUIRED_FIELDS,
  INTEGRATION_FORM_SHOW_VALIDATION_ERRORS,
} from 'constants/action_types'
import {
  INTEGRATION_METHOD_CREATE_NEW,
  INTEGRATION_FORM_NEXT_STEP_LOADING,
  INTEGRATION_FORM_NEXT_STEP_READY,
  INTEGRATION_FORM_NEXT_STEP_DISABLED,
  INTEGRATION_FORM_NEXT_STEP_HAS_TO_LOAD,
  INTEGRATION_FORM_INITIAL_LOADING,
  INTEGRATION_FORM_INITIAL_READY,
} from 'constants/integrations'
import {
  FETCH_BOARDS_REQUEST,
  FETCH_BOARDS_SUCCESS,
  FETCH_BOARDS_FAILURE,
  CREATE_CARD_REQUEST,
  CREATE_CARD_FAILURE,
  FETCH_BOARD_DETAILS_FAILURE,
  FETCH_BOARD_DETAILS_SUCCESS,
  FETCH_BOARD_DETAILS_REQUEST,
  FETCH_CARD_FOR_BOARD_REQUEST,
  FETCH_CARD_FOR_BOARD_FAILURE,
  FETCH_CARD_FOR_BOARD_SUCCESS,
  CREATE_CARD_SUCCESS,
} from 'ducks/integrations/trello'
import {
  CONVERSATION_LINK_RESOURCE_FAILED,
  CONVERSATION_LINK_RESOURCE_STARTED,
  CONVERSATION_LINK_RESOURCE_SUCCESS,
} from 'ducks/tickets/actionTypes'

export const initialState = {
  selectedIntegration: null,
  selectedMethod: INTEGRATION_METHOD_CREATE_NEW,
  isError: false,
  github: {},
  form: {
    values: {},
    nextStepDependencyFields: [],
    nextStepState: INTEGRATION_FORM_NEXT_STEP_DISABLED,
    initialLoadingState: INTEGRATION_FORM_INITIAL_READY,
  },
}

const reducers = {}

reducers[HIDE_MODAL] = () => initialState

reducers[INTEGRATIONS_INTEGRATION_SELECTED] = (_state, action) => ({
  ...initialState,
  selectedIntegration: action.item,
})

reducers[INTEGRATIONS_METHOD_CHANGED] = (state = initialState, action) => ({
  ...state,
  selectedMethod: action.item,
  form: initialState.form,
})

const cleanValues = (values, keysToClean) =>
  Object.entries(values).reduce(
    (cleanedValues, [key, value]) =>
      keysToClean.includes(key)
        ? cleanedValues
        : { ...cleanedValues, [key]: value },
    {}
  )

const setNextStepState = (
  currentState,
  nextStepDependencyFields,
  filledFields,
  currentlyUpdatedField
) => {
  if (nextStepDependencyFields && nextStepDependencyFields.length === 0)
    return INTEGRATION_FORM_NEXT_STEP_DISABLED
  const areDependenciesFullfield = nextStepDependencyFields.reduce(
    (areAllIncluded, dep) => areAllIncluded && filledFields.includes(dep),
    true
  )
  if (!areDependenciesFullfield) return INTEGRATION_FORM_NEXT_STEP_DISABLED
  if (
    (currentState === INTEGRATION_FORM_NEXT_STEP_DISABLED ||
      nextStepDependencyFields.includes(currentlyUpdatedField)) &&
    areDependenciesFullfield
  )
    return INTEGRATION_FORM_NEXT_STEP_HAS_TO_LOAD

  return currentState
}

reducers[INTEGRATIONS_CLEAN_FORM_VALUES] = (
  state = initialState,
  { item: { keys } }
) => {
  return {
    ...state,
    form: {
      ...state.form,
      values: cleanValues(state.form.values, keys),
      nextStepState: setNextStepState(
        state.form.nextStepState,
        state.form.nextStepDependencyFields,
        Object.keys(cleanValues(state.form.values, keys))
      ),
    },
  }
}

reducers[INTEGRATIONS_UPDATE_FORM_VALUE] = (state = initialState, action) => {
  const { id, value } = action.item
  return {
    ...state,
    form: {
      ...state.form,
      values: {
        ...state.form.values,
        [id]: value,
      },
      nextStepState: setNextStepState(
        state.form.nextStepState,
        state.form.nextStepDependencyFields,
        [...Object.keys(state.form.values), id],
        id
      ),
      validation:
        state.form.validation &&
        state.form.validation.reduce((validation, element) => {
          if (element.key === id)
            return [
              ...validation,
              {
                ...element,
                isValid: value !== '',
                show: false,
              },
            ]
          return [...validation, element]
        }, []),
    },
  }
}

reducers[INTEGRATION_FETCH_GITHUB_REPOS_REQUEST] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    initialLoadingState: INTEGRATION_FORM_INITIAL_LOADING,
  },
  isError: false,
})

reducers[INTEGRATION_FETCH_GITHUB_REPOS_SUCCESS] = (
  state = initialState,
  action
) => ({
  ...state,
  form: {
    ...state.form,
    values: {},
    nextStepState: INTEGRATION_FORM_NEXT_STEP_DISABLED,
    initialLoadingState: INTEGRATION_FORM_INITIAL_READY,
  },
  github: {
    repos: action.item.filter(
      ({ has_issues: hasIssues, permissions }) => hasIssues && permissions.pull
    ),
  },
  isError: false,
})
reducers[INTEGRATION_FETCH_GITHUB_REPOS_FAILURE] = (state = initialState) => ({
  ...state,
  isError: true,
})

reducers[INTEGRATION_FETCH_GITHUB_ISSUES_REQUEST] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    nextStepState: INTEGRATION_FORM_NEXT_STEP_LOADING,
  },
  isError: false,
})

reducers[INTEGRATION_FETCH_GITHUB_ISSUES_SUCCESS] = (
  state = initialState,
  action
) => {
  return {
    ...state,
    github: {
      ...state.github,
      issues: action.item.filter(issue => !issue.pull_request),
    },
    form: {
      ...state.form,
      nextStepState: INTEGRATION_FORM_NEXT_STEP_READY,
    },
    isError: false,
  }
}
reducers[INTEGRATION_FETCH_GITHUB_ISSUES_FAILURE] = (state = initialState) => ({
  ...state,
  isError: true,
})

reducers[INTEGRATION_FETCH_GITHUB_REPO_DETAILS_REQUEST] = (
  state = initialState
) => ({
  ...state,
  form: {
    ...state.form,
    nextStepState: INTEGRATION_FORM_NEXT_STEP_LOADING,
  },
  isError: false,
})

reducers[INTEGRATION_FETCH_GITHUB_REPO_DETAILS_SUCCESS] = (
  state = initialState,
  action
) => ({
  ...state,
  github: {
    ...state.github,
    ...action.item,
  },
  form: {
    ...state.form,
    nextStepState: INTEGRATION_FORM_NEXT_STEP_READY,
  },
  isError: false,
})

reducers[INTEGRATION_FETCH_GITHUB_REPO_DETAILS_FAILURE] = (
  state = initialState
) => ({
  ...state,
  isError: true,
})

reducers[FETCH_BOARDS_REQUEST] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    initialLoadingState: INTEGRATION_FORM_INITIAL_LOADING,
  },
  isError: false,
})

reducers[FETCH_BOARDS_SUCCESS] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    values: {},
    nextStepState: INTEGRATION_FORM_NEXT_STEP_DISABLED,
    initialLoadingState: INTEGRATION_FORM_INITIAL_READY,
  },
  isError: false,
})

reducers[FETCH_BOARDS_FAILURE] = (state = initialState) => ({
  ...state,
  isError: true,
})

reducers[FETCH_BOARD_DETAILS_REQUEST] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    nextStepState: INTEGRATION_FORM_NEXT_STEP_LOADING,
  },
  isError: false,
})

reducers[FETCH_BOARD_DETAILS_SUCCESS] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    nextStepState: INTEGRATION_FORM_NEXT_STEP_READY,
  },
  isError: false,
})

reducers[FETCH_BOARD_DETAILS_FAILURE] = (state = initialState) => ({
  ...state,
  isError: true,
})

reducers[FETCH_CARD_FOR_BOARD_REQUEST] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    nextStepState: INTEGRATION_FORM_NEXT_STEP_LOADING,
  },
  isError: false,
})

reducers[FETCH_CARD_FOR_BOARD_FAILURE] = (state = initialState) => ({
  ...state,
  isError: true,
})

reducers[FETCH_CARD_FOR_BOARD_SUCCESS] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    nextStepState: INTEGRATION_FORM_NEXT_STEP_READY,
  },
  isError: false,
})

reducers[CREATE_CARD_REQUEST] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    initialLoadingState: INTEGRATION_FORM_INITIAL_LOADING,
  },
  isError: false,
})

reducers[CREATE_CARD_SUCCESS] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    values: {},
    nextStepState: INTEGRATION_FORM_NEXT_STEP_DISABLED,
    initialLoadingState: INTEGRATION_FORM_INITIAL_READY,
  },
  isError: false,
})

reducers[CREATE_CARD_FAILURE] = (state = initialState) => ({
  ...state,
  isError: true,
})

reducers[INTEGRATION_CREATE_GITHUB_ISSUE_FAILURE] = (state = initialState) => ({
  ...state,
  isError: true,
})
reducers[INTEGRATION_CREATE_GITHUB_ISSUE_REQUEST] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    initialLoadingState: INTEGRATION_FORM_INITIAL_LOADING,
  },
  isError: false,
})

reducers[INTEGRATION_CREATE_GITHUB_ISSUE_SUCCESS] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    values: {},
    nextStepState: INTEGRATION_FORM_NEXT_STEP_DISABLED,
    initialLoadingState: INTEGRATION_FORM_INITIAL_READY,
  },
  isError: false,
})

reducers[INTEGRATION_SET_NEXT_STEP_DEPENDENCY_FIELDS] = (
  state = initialState,
  action
) => ({
  ...state,
  form: {
    ...state.form,
    nextStepDependencyFields: action.item,
  },
  isError: false,
})

reducers[CONVERSATION_LINK_RESOURCE_FAILED] = (state = initialState) => ({
  ...state,
  isError: true,
})

reducers[CONVERSATION_LINK_RESOURCE_STARTED] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    initialLoadingState: INTEGRATION_FORM_INITIAL_LOADING,
  },
  isError: false,
})

reducers[CONVERSATION_LINK_RESOURCE_SUCCESS] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    values: {},
    nextStepState: INTEGRATION_FORM_NEXT_STEP_DISABLED,
    initialLoadingState: INTEGRATION_FORM_INITIAL_READY,
  },
  isError: false,
})

const FIELD_IS_MISSING_MESSAGE = "Can't be empty"
reducers[INTEGRATION_FORM_SET_REQUIRED_FIELDS] = (
  state = initialState,
  action
) => ({
  ...state,
  form: {
    ...state.form,
    validation: action.item.reduce(
      (validation, field) => [
        ...validation,
        {
          key: field,
          show: false,
          message: FIELD_IS_MISSING_MESSAGE,
          isValid: false,
        },
      ],
      []
    ),
  },
})

reducers[INTEGRATION_FORM_SHOW_VALIDATION_ERRORS] = (state = initialState) => ({
  ...state,
  form: {
    ...state.form,
    validation: state.form.validation.map(field => ({
      ...field,
      show: !field.isValid,
    })),
  },
})

export default (state = initialState, action) => {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
