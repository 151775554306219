/* eslint-disable no-multi-assign */ // ok in reducers
import * as pages from 'constants/pages'
import * as types from 'constants/action_types'
import { getSearchMailboxesFromQueryString } from 'util/search'
import { any, emptyArr, getLength } from 'util/arrays'
import { createActionTypeReducer } from 'util/reducers'
import { CREATE_DRAFT, DELETE_DRAFT } from 'ducks/drafts2/constants'
import { CLEAR_SEARCH_TAG } from 'actions/search'
import { clearEditorAndAddBaseNode } from 'components/ConversationList/Header/Search/util'
import { isDeepEqual } from 'util/objects'

const reducers = {}

const defaultState = {
  byQueryId: {},
  // committedSearchQueryString and submittedSearchQueryString won't include mailbox filter
  committedSearchQueryString: null,
  submittedSearchQueryString: null,
  // Mailbox filters, e.g. ['inbox1', 'inbox2']
  searchMailboxIds: [],
  // Store the current search query part that has the selection (clicked or typed)
  currentPart: { operator: undefined, value: undefined, part: undefined },
  // The editor for Search input
  editor: null,
  editorState: undefined,
  lastAutoRedirectTicketId: null,
}

reducers[types.MARK_FETCHING_STATUS] = (state, action) => {
  const data = action.data
  if (data.action === 'fetchTicketsByKeyword') {
    let isFetching = state.isFetching || false
    isFetching = data.status
    return Object.assign({}, state, {
      isFetching,
    })
  }
  return state
}

function immerUpdateDraftSearches(
  draftState,
  direction, // INCREMENT or DECREMENT
  ticketId,
  currentUserId,
  draftFolderIds
) {
  if (ticketId === 'new') return

  const changedSearchIds = Object.keys(draftState.byQueryId).reduce(
    (acc, search) => {
      // we remove from current user's draft search
      const matchesDraftSearch =
        currentUserId && search.match(`draft:${currentUserId}`)
      const matchesDraftFolder = any(
        id => search.match(`folder:${id}`),
        draftFolderIds
      )
      if (matchesDraftSearch || matchesDraftFolder) acc.push(search)
      return acc
    },
    []
  )

  if (getLength(changedSearchIds) === 0) return

  changedSearchIds.forEach(search => {
    const searchObject = draftState.byQueryId[search]

    if (!searchObject.removedTicketIds) {
      searchObject.removedTicketIds = {}
    }
    if (!searchObject.addedTicketIds) {
      searchObject.addedTicketIds = {}
    }
    if (!searchObject.pages) {
      searchObject.pages = []
    }

    // the page is not necessarily present, but count is
    // if page is present, get a new copy and store it for usage
    // later in the function
    // if (searchObject && searchObject.pages && searchObject.pages[1]) {
    //   searchObject.pages = { ...searchObject.pages }
    //   searchObject.pages[1] = [].concat(searchObject.pages[1]) // copy the array
    //   page = searchObject.pages[1]
    // }

    if (direction === 'INCREMENT') {
      if (searchObject.pages[1] && !searchObject.pages[1].includes(ticketId)) {
        searchObject.pages[1].push(ticketId)
      }

      // as in the root reducer, we track added(/removed) ticket ids so
      // that we dont apply diffs (e.g. from realtime updates) twice
      if (!searchObject.addedTicketIds[ticketId]) {
        searchObject.totalCount += 1
        searchObject.addedTicketIds[ticketId] = true
        delete searchObject.removedTicketIds[ticketId]
      }

      if (
        searchObject.sortedIds &&
        searchObject.sortedIds.indexOf(ticketId) < 0
      ) {
        if (searchObject.sortOrder === 'oldest') {
          searchObject.sortedIds.push(ticketId)
        } else {
          searchObject.sortedIds.unshift(ticketId)
        }
      }
    } else if (direction === 'DECREMENT') {
      if (searchObject.pages[1]) {
        const idx = searchObject.pages[1].indexOf(ticketId)
        if (idx > -1) {
          searchObject.pages[1].splice(idx, 1)
        }
      }

      if (!searchObject.removedTicketIds[ticketId]) {
        searchObject.totalCount -= 1
        searchObject.removedTicketIds[ticketId] = true
        delete searchObject.addedTicketIds[ticketId]
      }

      if (
        searchObject.sortedIds &&
        searchObject.sortedIds.indexOf(ticketId) > -1
      ) {
        searchObject.sortedIds = searchObject.sortedIds.filter(
          existingId => existingId !== ticketId
        )
      }
    } else {
      throw new Error('Invalid action')
    }

    // negative counts make us look like asses
    if (searchObject.totalCount < 0) searchObject.totalCount = 0
  })
}

reducers[CREATE_DRAFT] = (draftState, action) => {
  const { ticketId } = action.payload
  const { currentUserId, draftFolderIds = emptyArr } = action.meta

  immerUpdateDraftSearches(
    draftState,
    'INCREMENT',
    ticketId,
    currentUserId,
    draftFolderIds
  )
}

reducers[DELETE_DRAFT] = (draftState, action) => {
  const { ticketId } = action.payload
  const { currentUserId, draftFolderIds = emptyArr } = action.meta

  immerUpdateDraftSearches(
    draftState,
    'DECREMENT',
    ticketId,
    currentUserId,
    draftFolderIds
  )
}

reducers[pages.TICKET_PAGE] = (state, { payload: { id }, meta }) => {
  const newState = {
    ...state,
  }

  let prevId

  if (
    meta &&
    meta.location &&
    meta.location.prev &&
    meta.location.prev.payload
  ) {
    prevId = meta.location.prev.payload.id
  }

  const ticketPathChanged = id !== prevId

  if (!ticketPathChanged) return state

  return newState
}

reducers[types.UPDATE_CURRENT_TICKET_SEARCH_QUERY] = (state, action) => {
  const {
    data: {
      commit,
      queryString,
      currentPart = defaultState.currentPart,
      submit,
      reset,
    },
  } = action
  const { committedSearchQueryString, editorState } = state
  if (reset || submit) {
    return {
      ...state,
      currentPart: defaultState.currentPart,
      committedSearchQueryString: null,
      submittedSearchQueryString: submit ? queryString : null,
      editorState: reset ? undefined : editorState,
      searchMailboxIds: getSearchMailboxesFromQueryString(queryString),
    }
  }

  if (!queryString && queryString !== '') {
    return { ...state, currentPart }
  }

  const newCommittedSearchQueryString = commit
    ? queryString
    : committedSearchQueryString

  return {
    ...state,
    currentPart,
    committedSearchQueryString: newCommittedSearchQueryString,
  }
}

reducers[pages.TICKETS_PAGE] = state => {
  const searchEditor = state.editor
  if (searchEditor) {
    searchEditor.update(clearEditorAndAddBaseNode, {
      tag: CLEAR_SEARCH_TAG,
      onUpdate: () => {
        searchEditor.blur()
      },
    })
  }
  return {
    ...state,
    currentPart: defaultState.currentPart,
    committedSearchQueryString: null,
    submittedSearchQueryString: null,
    editorState: undefined,
    searchMailboxIds: [],
  }
}

reducers[pages.SEARCH_PAGE] = (draftState, action) => {
  const searchEditor = draftState.editor
  const channels = action.meta?.query?.channel
  const search = action.meta?.query?.search
  const isAutoRedirect = action.payload?.isAutoRedirect || false
  const ticketId = action.payload?.id || null

  if (channels) {
    const newSearchMailboxIds = Array.isArray(channels) ? channels : [channels]

    if (!isDeepEqual(draftState.searchMailboxIds, newSearchMailboxIds)) {
      draftState.searchMailboxIds = newSearchMailboxIds
    }
  }
  // Set `isReloadingSubmittedSearchQuery` only on initial page load or when url is replaced
  // (example is when we're doing a legacy search redirect using doRedirectLegacySearch)
  if (
    ['load', 'replace'].includes(action.meta?.location?.kind) ||
    draftState.committedSearchQueryString !== search
  ) {
    draftState.isReloadingSubmittedSearchQuery = true
  }

  if (search) {
    draftState.committedSearchQueryString = search
  } else if (search === undefined && draftState.committedSearchQueryString) {
    if (searchEditor) {
      searchEditor.update(clearEditorAndAddBaseNode, {
        tag: CLEAR_SEARCH_TAG,
        onUpdate: () => {
          searchEditor.blur()
        },
      })
    }
    draftState.committedSearchQueryString = null
  }

  if (ticketId && isAutoRedirect) {
    draftState.lastAutoRedirectTicketId = ticketId
  }
  return draftState
}

reducers[types.TOGGLE_LIST_SEARCH_BOX_STATUS] = (state, action) => {
  if (!action.data) return state
  const {
    data: { isFocused },
  } = action

  return {
    ...state,
    listSearchBoxFocused: isFocused,
  }
}

reducers[types.SEARCH_MAILBOXES_UPDATE] = (state, action) => {
  return {
    ...state,
    searchMailboxIds: action.payload.mailboxes,
  }
}

reducers[types.SEARCH_UPDATE_BY_KEY] = (state, action) => {
  if (!action.payload.key) return state
  return {
    ...state,
    [action.payload.key]: action.payload.value,
  }
}

export default createActionTypeReducer(reducers, defaultState)
