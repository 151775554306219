import React from 'react'
import DOMPurify from 'dompurify'

function SanitizedHTML({ html, as, config, onBodyNode, ...rest }) {
  const Component = as || 'div'
  /* eslint-disable no-param-reassign */
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener noreferrer')
    }
    if (node.tagName === 'PRE' && node.style) {
      // when we meed a <pre>, make sure it's usable
      try {
        node.style.width = null
        node.style.height = null
        node.style.overflow = null
      } catch (e) {
        // pass
      }
    }
    // Remove app id, which is the page's root id and will apply styles to the message body
    if (node.id === 'app') {
      node.removeAttribute('id')
    }
  })
  /* eslint-enable no-param-reassign */
  const clean = DOMPurify.sanitize(html, config || {})

  return (
    <Component
      {...rest}
      ref={onBodyNode}
      dangerouslySetInnerHTML={{ __html: clean }}
    />
  )
}

export default React.memo(SanitizedHTML)
