import { createSelector } from 'reselect'
import { selectCurrentConversation } from 'ducks/tickets/selectors/selectCurrentConversation'

// DEPRECATED - causes too many rerenders. Prefer selectCurrentTicketCustomer
export const selectCurrentCustomer = createSelector(
  selectCurrentConversation,
  ticket => {
    return ticket ? ticket.customer : null
  }
)
