import { createSelector } from 'reselect'

import {
  isAddingNote,
  isReplyingDirect,
  isReplyingAll,
  isForwarding,
} from 'util/draft'
import { emptyArr } from 'util/arrays'
import {
  selectIsEditorFocused,
  selectIsNoteEditor,
  selectIsReplyEditor,
} from 'ducks/editor'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectProps } from './selectProps'

export const selectIsViewingTicket = createSelector(
  selectCurrentTicketId,
  ticketId => !!ticketId
)

export const selectIsViewingRoom = createSelector(
  selectProps,
  props => !!props.roomId
)

export const selectIsViewingConversation = createSelector(
  selectIsViewingTicket,
  selectIsViewingRoom,
  (isViewingTicket, isViewingRoom) => isViewingTicket || isViewingRoom
)

export const selectDraftType = createSelector(
  selectProps,
  props => props.draftType
)

export const selectIsReplyingDirect = createSelector(selectDraftType, type =>
  isReplyingDirect(type)
)

export const selectIsReplyingAll = createSelector(
  selectDraftType,
  selectIsReplyEditor,
  (type, isReplyEditor) => isReplyingAll(type) || isReplyEditor
)

export const selectIsAddingNote = createSelector(
  selectDraftType,
  selectIsNoteEditor,
  (type, isNoteEditor) => isAddingNote(type) || isNoteEditor
)

export const selectIsForwarding = createSelector(selectDraftType, type =>
  isForwarding(type)
)

export const selectIsReplying = createSelector(
  selectIsReplyingAll,
  selectIsReplyingDirect,
  selectIsEditorFocused,
  (replyingAll, replyingDirect, isFormFocused) =>
    (replyingAll || replyingDirect) && isFormFocused
)

export const selectIsCreatingNewEmail = createSelector(
  selectProps,
  props => props.isNewEmail
)

export const selectIsLoggingNewConversation = createSelector(
  selectProps,
  props => props.isNewConversation
)

export const selectIsViaKeyboard = createSelector(selectProps, props =>
  Boolean(props.viaKeyboard)
)

export const selectIsComposingMessage = createSelector(
  selectIsReplying,
  selectIsAddingNote,
  selectIsForwarding,
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
  (
    replying,
    addingNote,
    forwarding,
    creatingNewEmail,
    loggingNewConversation
  ) =>
    replying ||
    addingNote ||
    forwarding ||
    creatingNewEmail ||
    loggingNewConversation
)

export const selectIsReplyHeaderExpandedInProps = createSelector(
  selectProps,
  ({ isReplyHeaderExpanded }) => {
    return Boolean(isReplyHeaderExpanded)
  }
)

export const selectVisibleFields = createSelector(selectProps, props => {
  return props.visibleFields || emptyArr
})

export const selectShowCannedModal = createSelector(
  selectVisibleFields,
  fields => fields.includes('canned')
)
