/* eslint-disable no-multi-assign, func-names */
import Bugsnag from '@bugsnag/js'
import * as types from 'constants/action_types'
import * as kbTypes from 'subapps/kb/actions'
import * as deviceTypes from 'constants/device_types'
import * as pages from 'constants/pages'
import * as onboardingTypes from 'subapps/onboarding/types'
import { APP_BILLING_EXPIRED } from 'ducks/billing/types'
import { FETCH_ACCOUNT_SUCCESS } from 'constants/actionTypes/account'
import * as widgetTypes from 'ducks/widgets/types'
import {
  COPY_CUSTOMER_EMAIL,
  COPY_CUSTOMER_WEBSITE,
} from 'ducks/inAppCards/customer'
import { UPDATE_INTEGRATION_SETTINGS_RESPONSE } from 'ducks/integrations/types'
import { CREATE_CARD_SUCCESS as CREATE_TRELLO_CARD_SUCCESS } from 'ducks/integrations/trello'
import { CREATE_ISSUE_SUCCESS as CREATE_GITHUB_ISSUE_SUCCESS } from 'ducks/integrations/github'

import { isEmpty } from 'util/arrays'
import { buildAccountForBugsnag } from 'util/bugsnag'
import { isMobile } from 'util/media_query'
import storage from 'util/storage'
import { downcase } from 'util/strings'
import { captureVersion } from 'util/version'
import { UPDATE_CUSTOM_PROFILE_APP_SUCCESS } from 'ducks/integrations/customProfile/types'
import {
  FETCH_ACCOUNT_PREFERENCE_SUCCESS,
  UPDATE_ACCOUNT_PREFERENCES_SUCCESS,
  UPDATE_ACCOUNT_PREFERENCES_USAGE_ONBOARDING_STARTED,
} from 'ducks/accountPreferences/types'
import { buildId, getRawId } from 'util/globalId'
import {
  reducers as cannedRepliesReducers,
  initialState as cannedRepliesInitialState,
} from 'ducks/cannedReplies/reducers/app'
import { createActionTypeReducer } from 'util/reducers'
import { UPDATE_DRAFT } from 'ducks/drafts2/constants'
import {
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
} from 'ducks/agents/types'

function isAndroidRunningStandalone() {
  return window.matchMedia('(display-mode: standalone)').matches
}
function isiOSRunningStandalone() {
  return 'standalone' in window.navigator && window.navigator.standalone
}

const updateFetchingStatus = (state, key, newStatus) => {
  return {
    ...state,
    fetchingStatuses: { ...state.fetchingStatuses, [key]: newStatus },
  }
}

const reducers = {}
const auth = storage.get('auth') || {}
const isStandalone = isAndroidRunningStandalone() || isiOSRunningStandalone()

const defaultState = {
  version: captureVersion(),
  authenticated: auth.authenticated,
  bootstrapped: false,
  currentOpenNavSection: [],
  deviceType: isMobile() ? deviceTypes.MOBILE : deviceTypes.DESKTOP,
  editingTicketTitle: false,
  editingTicketTitleDraft: '',
  editorToolbarMessages: [],
  isRightSidebarCollapsed:
    storage.get('rightSidebarCollapsed') ||
    (storage.get('rightSidebarCollapsed') === null &&
      window.innerWidth <= 1400),
  isStandalone,
  isTicketListScrolledToTop: true,
  justClosedKeyboard: false,
  keysPressed: {},
  realtimeSubscribed: false,
  selectedMentionSelectorAgentId: null,
  snackbar: { open: false },
  suppressMentionSelector: false,
  tasks: [],
  token: auth.token,
  availableAccounts: [],
  printMode: false,
  accessLists: {
    mailbox: {},
    widget: {},
  },
  mentions: {},
  cannedReplyDropdown: cannedRepliesInitialState,
}

reducers[types.TOGGLE_RIGHT_SIDEBAR] = state => {
  const newValue = !state.isRightSidebarCollapsed
  storage.set('rightSidebarCollapsed', newValue)
  return {
    ...state,
    isRightSidebarCollapsed: newValue,
  }
}

reducers[types.KEY_PRESSED_CHANGE] = (state, action) => {
  return {
    ...state,
    keysPressed: {
      ...state.keysPressed,
      ...action.data.change,
    },
  }
}

reducers[types.FETCHED_INBOX_DATA] = state => ({
  ...state,
  bootstrappedInbox: true,
})

function createAccount(apiAccount) {
  const {
    integrationSettings, // These are managed under the integrations branch of the store
    ...newAccount
  } = apiAccount

  // GR HACK - if its a CI build, pretend beta flag is set
  if (newAccount.subdomain.match(/^fullstacktest/)) {
    newAccount.preferences = {
      ...(newAccount.preferences || {}),
      fullstack: true,
      groove_20_beta: true,
    }
  }
  Bugsnag.addMetadata('account', buildAccountForBugsnag(newAccount))
  return newAccount
}

reducers[types.UPDATE_REPORT_SETTINGS] = (state, action) => {
  const { payload } = action
  return {
    ...state,
    account: {
      ...state.account,
      preferences: {
        ...(state.account || {}).preferences,
        ...payload.account.preferences,
      },
    },
  }
}

reducers[types.UPDATE_APP_DATA] = (state, action) => {
  const { data } = action
  const account = createAccount(data.account)

  // This data is stored for lookup purposes and to prevent
  // a circular reference between app and mailbox selectors
  const mailboxes = data.mailboxes || []
  const mailboxIds = mailboxes.map(mailbox => mailbox.id)
  const mailboxFolderMap = [...mailboxes].reduce((object, mailbox) => {
    const folderIds = mailbox.folders.map(folder => folder.id)
    object[mailbox.id] = folderIds // eslint-disable-line no-param-reassign
    object[mailbox.name] = folderIds // eslint-disable-line no-param-reassign
    object[downcase(mailbox.name)] = folderIds // eslint-disable-line no-param-reassign
    return object
  }, {})

  let newState = {
    ...state,
    account,
    bootstrapped: true,
    mailboxFolderMap,
    mailboxIds,
    companyProfile: data.companyProfile,
  }

  if (data.accessLists) {
    data.accessLists.forEach(list => {
      newState = updateAccessList(newState, list)
    })
  }

  return newState
}

reducers[FETCH_ACCOUNT_SUCCESS] = (state, action) => {
  let {
    payload: { account },
  } = action
  account = createAccount(account)

  return {
    ...state,
    account,
  }
}

reducers[APP_BILLING_EXPIRED] = (state, { data }) => {
  if (!data.account) {
    return state
  }
  return {
    ...state,
    account: {
      ...(state.account || {}),
      ...createAccount(data.account),
    },
  }
}

reducers[UPDATE_DRAFT] = (state, action) => {
  const mailboxId = action.payload?.fields?.mailboxId
  if (!mailboxId) return state

  return { ...state, previousMailbox: mailboxId }
}

reducers[pages.FOLDER_PAGE] = (state, action) => {
  const newState = reducers[pages.MAIN_PAGE](state, action)
  const data = action.payload
  if (data) {
    const { folderId } = data

    newState.lastListPage = {
      type: 'folder',
      folderId,
    }
  }
  return newState
}

reducers[pages.TICKET_PAGE] = reducers[pages.TICKET_REPLY_PAGE] = reducers[
  pages.TICKET_REPLY_CHANGESET_PAGE
] = reducers[pages.TICKET_COMMENT_PAGE] = reducers[
  pages.TICKET_FORWARD_PAGE
] = reducers[pages.TICKET_FORWARD_CHANGESET_PAGE] = state => {
  return {
    ...state,
    editingTicketTitle: false,
  }
}

reducers[types.CLEAR_RETURN_PATH] = state => {
  return { ...state, returnTo: undefined }
}

reducers[types.TICKET_TITLE_EDITING_START] = (state, { data }) => {
  return {
    ...state,
    editingTicketTitle: true,
    editingTicketTitleDraft: data.currentTitle,
  }
}

reducers[types.TICKET_TITLE_EDITING_STOP] = state => {
  return {
    ...state,
    editingTicketTitle: false,
    editingTicketTitleDraft: '',
  }
}

reducers[types.TICKET_TITLE_UPDATE_REQUEST] = state => {
  return {
    ...state,
    editingTicketTitle: false,
    editingTicketTitleDraft: null,
  }
}

reducers[types.TICKET_TITLE_DRAFT_CHANGED] = (state, { data: { title } }) => {
  return {
    ...state,
    editingTicketTitleDraft: title,
  }
}

reducers[types.SHOW_SNACKBAR] = (state, action) => {
  return {
    ...state,
    snackbar: {
      open: true,
      props: { ...action.data },
    },
  }
}

reducers['*'] = (draftState, action) => {
  if (action.snackbar) {
    const { message, link, duration } = action.snackbar
    draftState.snackbar = {
      open: true,
      props: {
        id: action.meta?.requestId || Date.now(),
        duration,
        link: link?.href,
        linkText: link?.text,
        message,
      },
    }
  }
  return draftState
}

const showSnackbar = (state, options) => ({
  ...state,
  snackbar: {
    open: true,
    props: { ...options, duration: 3000 },
  },
})

reducers[CREATE_TRELLO_CARD_SUCCESS] = (state, { payload: { url } }) =>
  showSnackbar(state, {
    message: 'Trello card created',
    link: url,
    linkText: `View card`,
  })
reducers[CREATE_GITHUB_ISSUE_SUCCESS] = (
  state,
  {
    payload: {
      nodes: [{ url }],
    },
  }
) =>
  showSnackbar(state, {
    message: 'GitHub issue created',
    link: url,
    linkText: `View issue`,
  })
reducers[COPY_CUSTOMER_EMAIL] = state =>
  showSnackbar(state, { message: 'Email address copied to clipboard' })
reducers[COPY_CUSTOMER_WEBSITE] = state =>
  showSnackbar(state, { message: 'Website URL copied to clipboard' })
reducers[UPDATE_INTEGRATION_SETTINGS_RESPONSE] = state =>
  showSnackbar(state, { message: 'Integration settings saved' })

reducers[types.HIDE_SNACKBAR] = state => ({
  ...state,
  snackbar: { open: false },
})

reducers[types.UPDATE_REALTIME_STATUS] = (state, action) => {
  const { data } = action
  return {
    ...state,
    realtimeConnectedOnce: state.realtimeConnectedOnce || data.subscribed,
    realtimeSubscribed: data.subscribed,
  }
}

reducers[types.QUEUE_EDITOR_TOOLBAR_MESSAGE] = (state, action) => {
  const { editorToolbarMessages } = state
  const lastMessage = editorToolbarMessages[editorToolbarMessages.length - 1]
  if (lastMessage) {
    let same = true
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(action.data)) {
      same = lastMessage[key] === action.data[key]
      if (!same) {
        break
      }
    }
    if (same) {
      return state
    }
  }
  const newEditorToolbarMessages = editorToolbarMessages.slice()
  newEditorToolbarMessages.push(action.data)
  return { ...state, editorToolbarMessages: newEditorToolbarMessages }
}

reducers[types.PROCESS_NEXT_EDITOR_TOOLBAR_MESSAGE] = state => {
  const { editorToolbarMessages } = state
  const newEditorToolbarMessages = editorToolbarMessages.slice(
    1,
    editorToolbarMessages.length
  )
  return { ...state, editorToolbarMessages: newEditorToolbarMessages }
}

reducers[types.CLEAR_EDITOR_TOOLBAR_MESSAGES] = state => {
  return { ...state, editorToolbarMessages: [] }
}

reducers[types.SET_JUST_CLOSED_KEYBOARD] = (state, action) => {
  return { ...state, justClosedKeyboard: action.data.state }
}

reducers[types.UPDATE_APP_DEVICE_TYPE] = (
  state,
  { data: { desktop, mobile } = {} } = {}
) => {
  let { deviceType } = state
  if (desktop) deviceType = deviceTypes.DESKTOP
  if (mobile) deviceType = deviceTypes.MOBILE
  return {
    ...state,
    deviceType,
  }
}

function updateAccessList(state, list) {
  const channelId = list.channelId || list.mailboxId || ''
  const type = channelId.startsWith('wid_') ? 'widget' : 'mailbox'

  return {
    ...state,
    accessLists: {
      ...state.accessLists,
      [type]: {
        ...state.accessLists?.[type],
        [channelId]: list,
      },
    },
  }
}

function removeAccessList(state, list) {
  const channelId = list.channelId || list.mailboxId || ''
  const type = channelId.startsWith('wid_') ? 'widget' : 'mailbox'

  return {
    ...state,
    accessLists: {
      ...state.accessLists,
      [type]: {
        ...state.accessLists?.[type],
        [channelId]: undefined,
      },
    },
  }
}

// NOTE (jscheel): This is a bit of a hack. Access lists are not actually created
// on the backend for widgets, but we want to utilize the filtering for the assignment
// dropdowns in chat, and the selectors all depend on access lists. Therefore we
// emulate the access list behavior for widgets, and then scope the selectors by
// the current channel.
function createAccessListFromWidget(widget, groupsById) {
  const agentIds = new Set(widget.userIds)
  if (!isEmpty(widget.groupIds)) {
    widget.groupIds.forEach(groupId => {
      groupsById[groupId].agents.forEach(agent => {
        agentIds.add(agent.id)
      })
    })
  }

  return {
    channelId: buildId('Widget', getRawId(widget.id)),
    agentIds: [...agentIds],
    groupIds: [...widget.groupIds],
  }
}

reducers[types.UPDATE_ACCESS_LIST] = (state, action) => {
  return updateAccessList(state, action.data)
}

reducers[types.REMOVE_ACCESS_LIST] = (state, action) => {
  return removeAccessList(state, action.data)
}

reducers[widgetTypes.CREATE_WIDGET_SUCCESS] = reducers[
  widgetTypes.UPDATE_WIDGET_SUCCESS
] = reducers[widgetTypes.DELETE_WIDGET_SUCCESS] = (
  state,
  action,
  { groups }
) => {
  const {
    payload: { widget },
  } = action

  if (!widget) return state

  const groupsById = groups.reduce((memo, group) => {
    // eslint-disable-next-line no-param-reassign
    memo[group.id] = group
    return memo
  }, {})

  const accessList = createAccessListFromWidget(widget, groupsById)

  const fn =
    action.type === types.DELETE_WIDGET_SUCCESS
      ? removeAccessList
      : updateAccessList

  return fn(state, accessList)
}

reducers[widgetTypes.FETCH_WIDGETS_SUCCESS] = (state, action, { groups }) => {
  const {
    payload: { widgets },
  } = action

  let newState = { ...state }

  const groupsById = groups.reduce((memo, group) => {
    // eslint-disable-next-line no-param-reassign
    memo[group.id] = group
    return memo
  }, {})

  // HACK (jscheel): The original access lists only provide agentIds because the
  // backend figures out the agent ids from the groups, so we emulate this
  // behavior here so that both behave the same way. Once we fully unite the
  // channel types, we will be able to re-address permissions.
  widgets.forEach(widget => {
    const accessList = createAccessListFromWidget(widget, groupsById)
    newState = updateAccessList(newState, accessList)
  })

  return newState
}

reducers[types.LOGIN_REQUEST] = state => {
  return updateFetchingStatus(
    {
      ...state,
      latestLoginError: null,
    },
    'authenticating',
    true
  )
}

reducers[types.TOKEN_REFRESH_REQUEST] = state => {
  return updateFetchingStatus(
    {
      ...state,
    },
    'tokenRefresh',
    true
  )
}

reducers[types.TOKEN_REFRESH_SUCCESS] = (
  state,
  { data: { token, expiresAt } }
) => {
  return updateFetchingStatus(
    {
      ...state,
      token,
      tokenExpiresAt: expiresAt,
    },
    'tokenRefresh',
    false
  )
}

reducers[types.TOKEN_REFRESH_FAILURE] = state => {
  return updateFetchingStatus(
    {
      ...state,
    },
    'tokenRefresh',
    false
  )
}

reducers[types.LOGIN_SUCCESS] = (state, { data: { token, expiresAt } }) => {
  return updateFetchingStatus(
    {
      ...state,
      authenticated: true,
      latestLoginError: null,
      otpChallenged: false,
      otpIdentifier: null,
      userId: null,
      token,
      tokenExpiresAt: expiresAt,
      availableAccounts: [],
    },
    'authenticating',
    false
  )
}

reducers[types.LOGIN_CHALLENGE] = (
  state,
  { data: { token, otpIdentifier } }
) => {
  return updateFetchingStatus(
    {
      ...state,
      latestLoginError: state.otpChallenged ? 'Invalid code' : null, // NOTE (jscheel): If already challenged, assume that an invalid code was provided
      otpChallenged: true,
      otpIdentifier,
      userId: null,
      token,
    },
    'authenticating',
    false
  )
}

reducers[pages.LOGIN_MULTIPLE_ACCOUNTS_PAGE] = (
  state,
  { payload: { accounts, provider } }
) => {
  return {
    ...state,
    availableAccounts: accounts,
    auth: { provider },
    latestLoginError: null,
    userId: null,
  }
}

reducers[pages.LOGIN_AGENT_MISSING_PAGE] = reducers[types.LOGIN_ERROR] = (
  state,
  action
) => {
  return updateFetchingStatus(
    {
      ...state,
      latestLoginError: action && action.data && action.data.error,
      otpChallenged: false,
      otpIdentifier: null,
      userId: null,
      availableAccounts: [],
    },
    'authenticating',
    false
  )
}

// Clear reset password and forgot subdomain state
reducers[pages.LOGIN_PAGE] = state => {
  return {
    ...state,
    forgotIsSent: false,
    forgotLastMessage: '',
  }
}
reducers[pages.FORGOT_SUBDOMAIN_PAGE] = reducers[pages.LOGIN_PAGE]
reducers[pages.FORGOT_PASSWORD_PAGE] = reducers[pages.LOGIN_PAGE]

reducers[types.FORGOT_PASSWORD_SUCCESS] = (
  state,
  { data: { message, valid } }
) => {
  return updateFetchingStatus(
    {
      ...state,
      forgotIsSent: valid,
      forgotLastMessage: message,
    },
    'forgot',
    false
  )
}

reducers[types.FORGOT_PASSWORD_FAIL] = reducers[types.FORGOT_PASSWORD_SUCCESS]
reducers[types.FORGOT_SUBDOMAIN_SUCCESS] =
  reducers[types.FORGOT_PASSWORD_SUCCESS]
reducers[types.FORGOT_SUBDOMAIN_FAIL] = reducers[types.FORGOT_PASSWORD_SUCCESS]

reducers[types.LOAD_RESET_PASSWORD_REQUEST] = state => {
  return updateFetchingStatus(
    {
      ...state,
      changePasswordErrors: [],
      changePasswordUserId: null,
      changePasswordRequire2fa: null,
      changePasswordMissingToken: false,
    },
    'changepassword',
    true
  )
}

reducers[types.LOAD_RESET_PASSWORD_SUCCESS] = (
  state,
  { data: { id, require2fa } }
) => {
  return updateFetchingStatus(
    {
      ...state,
      changePasswordUserId: id,
      changePasswordRequire2fa: require2fa,
      changePasswordMissingToken: false,
    },
    'changepassword',
    false
  )
}

reducers[types.LOAD_RESET_PASSWORD_FAIL] = (
  state,
  { data: { message, missingToken } }
) => {
  return updateFetchingStatus(
    {
      ...state,
      changePasswordErrors: [message],
      changePasswordMissingToken: missingToken,
    },
    'changepassword',
    false
  )
}

reducers[CHANGE_PASSWORD_STARTED] = state => {
  return {
    ...state,
    changePasswordErrors: [],
  }
}
reducers[CHANGE_PASSWORD_SUCCESS] = (state, { payload }) => {
  const messages = payload.errors?.map(({ message }) => message)

  return {
    ...state,
    changePasswordErrors: messages,
  }
}

reducers[CHANGE_PASSWORD_FAILED] = (state, { payload }) => {
  const messages = payload.errors?.map(({ message }) => message)

  return {
    ...state,
    changePasswordErrors: messages,
  }
}

reducers[types.LOGOUT] = state => {
  const authLessDefaultState = { ...state, defaultState }
  authLessDefaultState.authenticated = false
  return authLessDefaultState
}

reducers[types.LIST_SCROLL_TOP_CHANGED] = (
  state,
  { data: { isTicketListScrolledToTop } }
) => {
  return {
    ...state,
    isTicketListScrolledToTop,
  }
}

reducers[types.UPDATE_AGENT_MENTION] = (state, action) => {
  const mention = action.data
  if (mention !== state.agentMention) {
    let reset = {}
    if (!mention) {
      reset = {
        selectedMentionSelectorAgentId: null,
        suppressMentionSelector: false,
      }
    }
    return {
      ...state,
      ...reset,
      agentMention: mention,
    }
  }
  return state
}

reducers[types.UPDATE_MENTIONS] = (state, action) => {
  const agentId = action.data
  if (agentId) {
    const originalMentionCount = state.mentions[agentId]
    return {
      ...state,
      mentions: {
        ...state.mentions,
        [agentId]: originalMentionCount ? originalMentionCount + 1 : 1,
      },
    }
  }
  return state
}

reducers[types.UPDATE_SELECTED_MENTION_SELECTOR_AGENT_ID] = (state, action) => {
  const agentId = action.data
  if (agentId !== state.selectedMentionSelectorAgentId) {
    return {
      ...state,
      selectedMentionSelectorAgentId: agentId,
    }
  }
  return state
}

reducers[types.UPDATE_SUPPRESS_MENTION_SELECTOR] = (state, action) => {
  if (action.data !== state.suppressMentionSelector) {
    return {
      ...state,
      suppressMentionSelector: action.data,
    }
  }
  return state
}

reducers[pages.TICKET_COMMENT_PAGE] = state => {
  return {
    ...state,
    suppressMentionSelector: false,
    agentMention: null,
    selectedMentionSelectorAgentId: null,
  }
}

// _PAGE reducers

reducers[pages.AUTH_CALLBACK_PAGE] = (state, action) => {
  const {
    meta: {
      query: { access_token: token },
    },
  } = action
  return {
    ...state,
    authenticated: true,
    token,
  }
}

reducers[pages.LOGOUT_PAGE] = state => {
  return {
    ...state,
    authenticated: false,
    token: null,
  }
}

// Created as a helper to test UI for changes in beta flag
reducers[types.ACCOUNT_PREFS_CHANGED] = (state, { data }) => ({
  ...state,
  account: {
    ...state.account,
    preferences: {
      ...state.account.preferences,
      ...data,
    },
  },
})

reducers[FETCH_ACCOUNT_PREFERENCE_SUCCESS] = (state, action) => {
  const { payload: { account } = {} } = action || {}
  if (!account) {
    return state
  }
  return {
    ...state,
    account: {
      ...state.account,
      preferences: {
        ...state.account.preferences,
        ...account.preferences,
      },
    },
  }
}

reducers[UPDATE_ACCOUNT_PREFERENCES_SUCCESS] = (state, action) => {
  const { request: { parameters: { account: preferences } = {} } = {} } =
    action || {}
  if (!preferences) {
    return state
  }
  const strippedPrefers = Object.entries(preferences).reduce(
    (newPreferences, [key, value]) => {
      const newKey = key.startsWith('prefers_') ? key.substring(8) : key
      try {
        // eslint-disable-next-line no-param-reassign
        newPreferences[newKey] = JSON.parse(value)
      } catch (e) {
        // eslint-disable-next-line no-param-reassign
        newPreferences[newKey] = value
      }
      return newPreferences
    },
    {}
  )
  return {
    ...state,
    account: {
      ...state.account,
      preferences: {
        ...state.account.preferences,
        ...strippedPrefers,
      },
    },
  }
}

reducers[UPDATE_ACCOUNT_PREFERENCES_USAGE_ONBOARDING_STARTED] = (
  state,
  action
) => {
  const { payload: { usageOnboarding } = {} } = action || {}
  if (!usageOnboarding) {
    return state
  }
  return {
    ...state,
    account: {
      ...state.account,
      preferences: {
        ...state.account.preferences,
        usage_onboarding: {
          ...state.account.preferences.usage_onboarding,
          ...usageOnboarding,
        },
      },
    },
  }
}

reducers[kbTypes.CREATE_KNOWLEDGE_BASE_SUCCESS] = (state, action) => {
  const { createdKnowledgeBase } = action.data

  if (createdKnowledgeBase.primary !== true) {
    return state
  }

  return {
    ...state,
    account: {
      ...state.account,
      primary_knowledge_base_id: createdKnowledgeBase.id,
    },
  }
}

reducers[kbTypes.UPDATE_KNOWLEDGE_BASE_SUCCESS] = (state, action) => {
  const { updateKnowledgeBase } = action.payload

  // Primary is unchanged
  if (
    state.account.primary_knowledge_base_id === updateKnowledgeBase.id ||
    updateKnowledgeBase.primary !== true
  ) {
    return state
  }

  return {
    ...state,
    account: {
      ...state.account,
      primary_knowledge_base_id: updateKnowledgeBase.id,
    },
  }
}

reducers[onboardingTypes.SIGNUP_SUCCESS] = (state, action) => {
  const { account, user } = action.payload
  return {
    ...state,
    account: {
      ...account,
    },
    token: user.token,
  }
}

reducers[onboardingTypes.ONBOARDING_COMPLETE_SUCCESS] = state => {
  return {
    ...state,
    account: {
      ...state.account,
      is_onboarding_completed: true,
    },
  }
}

reducers[pages.OTP_CHALLENGE_PAGE] = reducers[types.ADD_RETURN_TO] = reducers[
  pages.LOGIN_PAGE
] = (state, action) => {
  const {
    payload: { returnTo: returnToPayload } = {},
    meta: { query: { returnTo: returnToQuery } = {} } = {},
  } =
    action || {}

  if (!returnToPayload && !returnToQuery) return state

  return {
    ...state,
    // We prioritize payload returnTos over query string because those are set
    // via code (aka developer implemented it)
    returnTo: returnToPayload || returnToQuery,
  }
}

reducers[types.CLEAR_RETURN_TO] = state => {
  return {
    ...state,
    returnTo: null,
  }
}

reducers[types.TOGGLE_PRINT_MODE] = (state, action) => {
  const { printMode } = action.payload

  return {
    ...state,
    printMode,
  }
}

reducers[types.UPDATE_ACCOUNT_SUCCESS] = (state, action) => {
  const { updateAccount: account } = action.payload
  return {
    ...state,
    account: {
      ...state.account,
      ...account,
      preferences: {
        ...state.account.preferences,
        ...account.preferences,
      },
    },
  }
}

reducers[UPDATE_CUSTOM_PROFILE_APP_SUCCESS] = (state, action) => {
  const { enabled } = action.payload
  return {
    ...state,
    account: {
      ...state.account,
      preferences: {
        ...state.account.preferences,
        custom_profile_app_installed: enabled,
      },
    },
  }
}

Object.assign(reducers, cannedRepliesReducers)

export default createActionTypeReducer(reducers, defaultState)
