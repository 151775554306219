import { createSelector } from 'reselect'
import { fetchingStatusesSelector, selectAccount } from 'selectors/app'
import { createBasicSelector } from 'util/redux'
import { emptyArr } from 'util/arrays'

const selectBase = state => state.app

const selectLocationBase = state => state.location

export const selectLastForgotMessage = createSelector(
  selectBase,
  base => (!base.forgotIsSent ? base.forgotLastMessage : '')
)

export const selectLastForgotIsSent = createSelector(
  selectBase,
  base => base.forgotIsSent
)

export const selectChangePasswordRequire2fa = createSelector(
  selectBase,
  base => base.changePasswordRequire2fa
)

export const selectChangePasswordErrors = createBasicSelector(
  selectBase,
  base => base.changePasswordErrors || emptyArr
)

export const selectResetPasswordToken = createSelector(
  selectLocationBase,
  base => base.query.reset_password_token
)

export const selectIsValidResetPasswordToken = createSelector(
  selectBase,
  base => !base.changePasswordMissingToken
)

export const selectIsLoadingChangePassword = createSelector(
  fetchingStatusesSelector,
  statuses => statuses.changepassword
)

export const selectIsEcommerce = createSelector(
  selectBase,
  base => base.account?.industry === 'E-Commerce'
)

export const selectAccountCreatedAt = createBasicSelector(
  selectBase,
  base => base.account?.activated_at
)

export const selectIsTestAccount = createBasicSelector(
  selectAccount,
  ({ subdomain }) => {
    if (!subdomain) return false
    return subdomain.startsWith('fullstacktest') || subdomain === 'perf'
  }
)
