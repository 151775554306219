import { createBasicSelector } from 'util/redux'

export const selectReassignTicketOnReply = state =>
  state.tickets.reassignTicketOnReply

export const selectReassignTicketByType = createBasicSelector(state => {
  return {
    note: state.tickets.reassignTicketOnNote,
    reply: state.tickets.reassignTicketOnReply,
  }
})
