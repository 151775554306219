import React from 'react'

import {
  parseUserTime,
  to12HourTime,
  minuteIntervalsIn24Hours,
} from 'util/date'
import { propFunc } from 'util/functions'
import { Dropdown, Input } from 'util/ui'

import styles from './styles.less'

export default class TimeInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleItemMouseDown = this.handleItemMouseDown.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputFocus = this.handleInputFocus.bind(this)
    this.inputRef = React.createRef()
    this.state = {}
  }

  doFocus() {
    if (this.inputRef) this.inputRef.current.focus()
  }

  handleInputBlur() {
    const {
      inputRef: {
        current: {
          inputRef: {
            current: { value },
          },
        },
      },
      props: { onChange },
    } = this
    const parsedValue = parseUserTime(value)
    this.setState({ focused: false, inputValue: null, value: parsedValue })
    if (onChange) onChange(parsedValue)
  }

  handleInputChange(_, { value }) {
    this.setState({ inputValue: value })
  }

  handleInputFocus() {
    const {
      props: { value: propsValue },
      state: { value: stateValue },
    } = this
    const value = propsValue || stateValue
    this.setState({ focused: true, inputValue: to12HourTime(value) })
  }

  handleItemMouseDown(value) {
    this.setState({ inputValue: value })
  }

  render() {
    const {
      props: { disabled, nullValue, value: propsValue },
      state: { inputValue, focused, value: stateValue },
    } = this
    const value = nullValue ? '' : propsValue || stateValue || ''
    return (
      <div className={styles.TimeInput}>
        <Dropdown
          disabled={disabled}
          icon={null}
          open={focused && !disabled}
          selectOnBlur={false}
          selectOnNavigation={false}
          scrolling
          tabIndex={-1}
          trigger={
            <Input
              className={styles.Input}
              icon="angle down"
              onBlur={this.handleInputBlur}
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              placeholder={'Select time'}
              ref={this.inputRef}
              value={
                inputValue || inputValue === ''
                  ? inputValue
                  : to12HourTime(value) || ''
              }
            />
          }
          value={value}
        >
          {
            <Dropdown.Menu>
              {minuteIntervalsIn24Hours(30).map(time => (
                <Dropdown.Item
                  key={time}
                  onMouseDown={propFunc(this.handleItemMouseDown, time)}
                  selected={value === time}
                  text={to12HourTime(time)}
                  value={time}
                />
              ))}
            </Dropdown.Menu>
          }
        </Dropdown>
      </div>
    )
  }
}
