import { emptyArr } from 'util/arrays'

export const selectSelectedTicketIds = state =>
  state?.tickets?.selected || emptyArr

export const selectSelectedPivotId = state => state.tickets.selectedPivotId

export const selectSelectedRangeEndId = state =>
  state.tickets.selectedRangeEndId

export const selectIsTicketSelectionMode = state =>
  !!state.tickets.isBulkSelectionMode
