import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import { selectAgentCount } from 'selectors/agents/base'

import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'

import { selectMailbox } from 'ducks/mailboxes/selectors/selectMailbox'
import { selectHasAnyMailboxByStates } from 'ducks/mailboxes/selectors/selectHasAnyMailboxByStates'
import { CHANNEL_STATE } from 'ducks/mailboxes/constants'
import { selectFlag } from 'ducks/flags/selectors'
import { HIDE_BOOK_DEMO_CARD, HIDE_TEAMMATES_CARD } from 'ducks/flags/flagNames'
import { selectIsTestAccount } from './account'

export const selectedBaseStore = state => state.foldersV2

export const selectLeftNavMailboxes = createSelector(
  selectedBaseStore,
  store => store.collectionsById
)

export const selectLeftNavShowBookDemoCard = createSelector(
  selectCurrentUserIsAdminOrOwner,
  state => selectFlag(state)(HIDE_BOOK_DEMO_CARD),
  selectIsTestAccount,
  // eslint-disable-next-line no-unused-vars
  (isManager, hideBookDemoCard, isTestAccount) => {
    // TEMP: hide book demo card
    // return isManager && hideBookDemoCard === false && !isTestAccount
    return false
  }
)

const confirmedStates = [CHANNEL_STATE.ACTIVE, CHANNEL_STATE.CONFIRMED]
export const selectLeftNavShowTeammatesCard = createCachedSelector(
  selectCurrentUserIsAdminOrOwner,
  selectAgentCount,
  selectMailbox,
  state => selectFlag(state)(HIDE_TEAMMATES_CARD),
  state => selectHasAnyMailboxByStates(state, confirmedStates),
  selectIsTestAccount,
  (
    isManager,
    agentCount,
    mailbox,
    hideTeammatesCard,
    hasAnyMailboxByStates,
    isTestAccount
  ) => {
    if (isTestAccount) return false

    let isMailboxConfirmed = false
    if (mailbox) {
      isMailboxConfirmed = confirmedStates.includes(mailbox.state)
    } else {
      // Unified mailbox
      isMailboxConfirmed = hasAnyMailboxByStates
    }
    return (
      isManager &&
      agentCount === 1 &&
      hideTeammatesCard === false &&
      isMailboxConfirmed
    )
  }
)((_state, mailboxId) => mailboxId || 'unknown')
