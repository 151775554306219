import { createSelector } from 'reselect'

import { selectCurrentDraftContactId } from 'ducks/drafts2/selectors'
import { selectCurrentConversation } from '../../../ducks/tickets/selectors/selectCurrentConversation'

export const selectCurrentConversationContactId = createSelector(
  selectCurrentConversation,
  ticket => {
    return ticket && ticket.contact
  }
)

export const selectCurrentConversationOrConversationDraftContactId = createSelector(
  selectCurrentConversationContactId,
  selectCurrentDraftContactId,
  (ticketContactId, draftContactId) => {
    return ticketContactId || draftContactId
  }
)

export const selectCurrentConversationContactIdOrTemp = createSelector(
  selectCurrentConversation,
  selectCurrentConversationContactId,
  (ticket, contactId) => {
    return contactId || (ticket && `forConversation:${ticket.id}`)
  }
)

export const selectCurrentConversationOrConversationDraftContactIdOrTemp = createSelector(
  selectCurrentConversation,
  selectCurrentConversationOrConversationDraftContactId,
  (ticket, contactId) => {
    return contactId || (ticket && `forConversation:${ticket.id}`)
  }
)
