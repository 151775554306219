import { createSelector } from 'reselect'

import { selectCurrentMailboxAgents } from 'ducks/accessList/selectors'
import {
  selectGroupsById,
  isAgentInGroup,
  isBoostrappedSelector,
} from 'selectors/app'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectAgentsById, selectAgents } from 'selectors/agents/base'

import { selectTicketAssigning, selectModalProps } from 'selectors/modals_base'
import { selectIsInInbox } from 'selectors/location'

import { difference, emptyArr } from 'util/arrays'
import { curry } from 'util/functions'
import { caselessMatch } from 'util/strings'
import { hasProp, keys } from 'util/objects'
import { selectGroupsFilteredByCurrentChannelAccessList } from './app/groups_filtered'
import { selectCurrentMailboxId } from '../ducks/mailboxes/selectors/selectCurrentMailboxId'

export { selectTicketAssigning }

const selectAssignmentsByMailboxId = state =>
  state.assignments.byMailboxId || {}

export const selectAssignmentsByGroupId = createSelector(
  selectAssignmentsByMailboxId,
  selectCurrentMailboxId,
  (byId, mailboxId) => {
    if (!byId[mailboxId]) return {}
    return byId[mailboxId].byGroupId || {}
  }
)

export const selectIsFetching = state => state.assignments.fetching

// Fetching the 'Any' group assignments so the first assignment modal page can
// show the suggested assignees
export const selectShouldFetchDefaultTopAssignments = createSelector(
  selectAssignmentsByGroupId,
  selectIsFetching,
  isBoostrappedSelector,
  selectIsInInbox,
  (byGroupId, fetching, isBoostrapped, inInbox) =>
    inInbox && isBoostrapped && !fetching && !hasProp(byGroupId, null)
)

export const selectRemainingAssignmentGroups = createSelector(
  selectAssignmentsByGroupId,
  selectGroupsFilteredByCurrentChannelAccessList,
  (byGroupId, groups) => {
    return difference(groups.map(e => e.id), keys(byGroupId))
  }
)

export const selectHasRemainingAssignmentGroups = createSelector(
  selectIsFetching,
  selectRemainingAssignmentGroups,
  (fetching, remaining) => !fetching && remaining.length > 0
)

// Only trigger fetching the remaining assignments per group when the
// assignment modal is showing (and they havent been loaded yet)
export const selectShouldFetchRemainingTopAssignments = createSelector(
  selectTicketAssigning,
  selectHasRemainingAssignmentGroups,
  selectIsInInbox,
  (isAssigning, hasRemaining, inInbox) => {
    return inInbox && isAssigning && hasRemaining
  }
)

const selectTicketAssignmentFilter = createSelector(
  selectModalProps,
  props => props.ticketAssignmentFilter
)

const selectTicketGroupAssignmentFilter = createSelector(
  selectModalProps,
  props => props.ticketGroupAssignmentFilter
)

export const selectIsFilteringPossibleAssignees = createSelector(
  selectTicketAssignmentFilter,
  filter => Boolean(filter && filter.length > 0)
)

export const selectDrilledDownGroupId = createSelector(
  selectModalProps,
  props => props.drilledDownGroupId
)

export const selectSelectedAgentId = createSelector(
  selectModalProps,
  props => props.selectedAgentId
)

export const selectSelectedGroup = createSelector(
  selectDrilledDownGroupId,
  selectGroupsById,
  (groupId, groups) => groups[groupId] || null
)

export const selectSelectedGroupAgentsCount = createSelector(
  selectSelectedGroup,
  group => (group ? group.agents.length : 0)
)

export const selectSelectedAgent = createSelector(
  selectSelectedAgentId,
  selectAgentsById,
  (agentId, agents) => agents[agentId] || null
)

function filterAgentsInGroup(agents, group) {
  if (!group) return agents // Include all agents if 'Any' group.
  return agents.filter(agent => {
    return isAgentInGroup(group, agent.id)
  })
}

export const selectAgentsInSelectedGroup = createSelector(
  selectCurrentMailboxAgents, // filter by those who can access current mailbox
  selectSelectedGroup,
  filterAgentsInGroup
)

function filterAgents(agents, term, currentUser) {
  if (!term) return agents
  const lowerCaseTerm = term.toLowerCase()
  if (lowerCaseTerm === 'you' || lowerCaseTerm === 'me')
    return agents.filter(agent => agent.id === currentUser.id)
  const matches = curry(caselessMatch)(term)
  return agents.filter(agent => matches(agent.name) || matches(agent.email))
}

export const selectFilteredAgentsInSelectedGroup = createSelector(
  selectAgentsInSelectedGroup,
  selectTicketAssignmentFilter,
  selectCurrentUser,
  filterAgents
)

export const selectFilteredAgentsInAllGroups = createSelector(
  selectCurrentMailboxAgents,
  selectTicketAssignmentFilter,
  selectCurrentUser,
  filterAgents
)

export const selectFilteredGroups = createSelector(
  selectGroupsFilteredByCurrentChannelAccessList,
  selectTicketGroupAssignmentFilter,
  (groups, term) => {
    if (!term) return groups
    const matches = curry(caselessMatch)(term)
    return groups.filter(group => matches(group.name))
  }
)

export const selectFilteredGroupsFromAgentFilter = createSelector(
  selectGroupsFilteredByCurrentChannelAccessList,
  selectTicketAssignmentFilter,
  (groups, term) => {
    if (!term) return []
    const matches = curry(caselessMatch)(term)
    return groups.filter(group => matches(group.name))
  }
)

function topAssignedAgentsByGroup(topAssigned, groupId = null, agents) {
  const group = topAssigned[groupId]
  if (!group) return emptyArr
  const result = Object.keys(group)
    .sort((a, b) => group[b] - group[a])
    .map(agentId => agents[agentId])
    .filter(Boolean)
  return result.length > 0 ? result : emptyArr
}

export const selectTopAssignedAgents = createSelector(
  selectAssignmentsByGroupId,
  selectDrilledDownGroupId,
  selectAgentsById,
  (topAssigned, groupId = null, agents) => {
    return topAssignedAgentsByGroup(topAssigned, groupId, agents)
  }
)

// Selects Top Assigned, or first two.
export const selectSuggestedAgents = createSelector(
  selectTopAssignedAgents,
  selectAgents,
  selectCurrentMailboxAgents,
  (topAssigned, agents = emptyArr, currentMailboxAgents = emptyArr) => {
    return topAssigned
      .concat(agents)
      .filter(agent =>
        currentMailboxAgents.some(a => a.agentId === agent.agentId)
      )
      .slice(0, 2)
  }
)

export const selectTopAssignedAgentsAnyGroup = createSelector(
  selectAssignmentsByGroupId,
  selectAgentsById,
  (topAssigned, agents) => topAssignedAgentsByGroup(topAssigned, null, agents)
)

// Selects Top Assigned, or first two.
export const selectSuggestedAgentsAnyGroup = createSelector(
  selectTopAssignedAgentsAnyGroup,
  selectAgents,
  (topAssigned, agents = []) =>
    topAssigned.concat(agents.slice(0, 2)).slice(0, 2)
)
