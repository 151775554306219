import { createSelector } from 'reselect'
import { selectCurrentTagsById } from 'ducks/tags/selectors'
import { selectCurrentOrSelectedTickets } from 'selectors/ticket_list/current'
import { emptyArr, isEmpty, uniq, flatMap } from 'util/arrays'
import { selectCurrentConversation } from '../../ducks/tickets/selectors/selectCurrentConversation'

export const selectCurrentTicketLabelIds = createSelector(
  selectCurrentConversation,
  ticket => {
    if (!ticket || !ticket.tags) return emptyArr
    if (isEmpty(ticket.tags)) return emptyArr // perf: same empty array
    return ticket.tags
  }
)

export const selectCurrentTicketLabels = createSelector(
  selectCurrentTicketLabelIds,
  selectCurrentTagsById,
  (labelIds, byId) => labelIds.map(id => byId[id]).filter(e => e)
)

const selectSelectedTicketsOrCurrentTicketLabelIds = createSelector(
  selectCurrentOrSelectedTickets,
  tickets => {
    if (!tickets || tickets.length === 0) return emptyArr // perf: same empty array
    const labelIds = uniq(flatMap(tickets, t => (t && t.tags) || []))
    if (isEmpty(labelIds)) return emptyArr // perf: same empty array
    return labelIds
  }
)

export const selectSelectedTicketsOrCurrentTicketLabels = createSelector(
  selectSelectedTicketsOrCurrentTicketLabelIds,
  selectCurrentTagsById,
  (labelIds, byId) => labelIds.map(id => byId[id]).filter(e => e)
)
