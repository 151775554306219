import { createSelector } from 'reselect'

import { getAgentUsernameOrYou, getAgentUsername } from 'util/agents'
import { mapObject, emptyObj } from 'util/objects'
import { CHAT_ADMIN_NAME } from 'ducks/chat/utils/constants'
import { selectCurrentEntitiesById } from 'ducks/entities/selectors'
import selectGroups from 'ducks/teams/selectors/selectGroups'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { getRawId } from 'util/globalId'
import { AGENT_ROLE_ADMIN, AGENT_ROLE_OWNER } from 'ducks/agents/constants'
import { createBasicSelector } from 'util/redux'
import selectByIdIncArchived from './selectByIdIncArchived'
import selectAgentsById from './selectAgentsById'
import decorate from './decorate'

export { selectAgentsById }

export const selectRawAgentsById = state => state.agents.byId || {}

export const selectAllAgentsFetched = state => !!state.agents.fetchedAll

export function selectAgent(state, id) {
  return selectRawAgentsById(state)[id] || emptyObj
}

export const selectAgentsByIdIncludingArchived = createSelector(
  selectByIdIncArchived,
  selectCurrentUser,
  selectGroups,
  (byId, currentUser, groups) =>
    mapObject(decorate(currentUser, groups))(byId || {})
)

export const selectAgents = createSelector(selectAgentsById, byId =>
  Object.values(byId)
)

export const selectAgentsIncludingArchived = createSelector(
  selectAgentsByIdIncludingArchived,
  byId => Object.values(byId)
)

export const selectAdminAgents = createSelector(selectAgents, agents =>
  agents.filter(
    agent => agent.role === AGENT_ROLE_ADMIN || agent.role === AGENT_ROLE_OWNER
  )
)

export const selectOwnerAgent = createSelector(selectAgents, agents =>
  agents.find(agent => agent.role === AGENT_ROLE_OWNER)
)

export const selectAgentIncludeGroups = createSelector(
  selectAgents,
  selectGroups,
  (agents, groups) => {
    return agents.map(agent => ({
      ...agent,
      groups: groups.filter(g => g.agents.some(a => a.id === agent.id)),
    }))
  }
)

export const selectAgentCount = createSelector(
  selectAgents,
  agents => agents.length
)

export const selectAgentTotalCount = state => state.agents.totalCount

export const selectCurrentAgent = createSelector(
  selectAgentsById,
  selectCurrentUser,
  (agentsById, currentUser) => agentsById[currentUser.id]
)

export const selectAgentsByUsername = createSelector(selectAgents, agents => {
  return agents.reduce((hash, agent) => {
    hash[getAgentUsername(agent)] = agent // eslint-disable-line no-param-reassign
    return hash
  }, {})
})

const makeSelectAgentsSortedBy = (sortBy, includeArchived = false) => {
  const agentsSelector = includeArchived
    ? selectAgentsIncludingArchived
    : selectAgents

  return createSelector(
    agentsSelector,
    selectCurrentUser,
    (agents, currentUser) => {
      return [].concat(agents).sort((a, b) => {
        if (a.id === currentUser.id) {
          return -1
        } else if (b.id === currentUser.id) {
          return 1
        }
        // eslint-disable-next-line no-nested-ternary
        return a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0
      })
    }
  )
}

export const selectAgentsSortedByUsername = makeSelectAgentsSortedBy('username')
export const selectAgentsSortedByName = makeSelectAgentsSortedBy('name')
export const selectAgentsIncludingArchivedSortedByUsername = makeSelectAgentsSortedBy(
  'username',
  true
)
export const selectAgentsIncludingArchivedSortedByName = makeSelectAgentsSortedBy(
  'name',
  true
)

export const selectRawAgents = createSelector(selectRawAgentsById, byId =>
  Object.values(byId)
)

export const selectAgentById = createBasicSelector(
  selectRawAgentsById,
  (_, agentId) => getRawId(agentId),
  (rawAgentsById, agentId) => rawAgentsById[agentId]
)

export function selectMxEventFromProps(_, props) {
  return props.mxEvent || null
}

export function makeSelectAgentUsernameFromMxEvent() {
  return createSelector(
    selectRawAgentsById,
    selectCurrentUser,
    selectMxEventFromProps,
    (agentsById, currentUser, mxEvent) => {
      if (!mxEvent) return null
      const senderId = mxEvent.getSender()
      if (senderId.startsWith('@chat-admin')) return CHAT_ADMIN_NAME
      const agent = Object.values(agentsById).find(a => a.chatId === senderId)
      if (!agent) return null
      return getAgentUsernameOrYou(agent, currentUser, agentsById)
    }
  )
}

export const selectCurrentAgentsById = state =>
  selectCurrentEntitiesById(state, 'agent')

export const selectAllAgentsEmails = createSelector(
  selectAgentsByIdIncludingArchived,
  agentsById => Object.values(agentsById).map(agent => agent.email)
)
