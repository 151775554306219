import querySerializer from 'query-string'
import Cookies from 'js-cookie'
import config from 'config'
import { redirect } from 'redux-first-router'
import { PUBLIC_PAGES } from 'actions/auth'
import { LOGIN_PAGE, SETTINGS_BILLING_PAGE, LOGOUT_PAGE } from 'constants/pages'
import storage from 'util/storage'
import { selectIsBillingAccountActive } from 'ducks/billing/selectors/selectIsBillingAccountActive'
import { oauthTokenSelector } from 'selectors/app'
import { CLICK_ID_KEY } from 'ducks/affiliates/types'
import {
  V6_EMAIL_PAGE,
  V6_SIGNUP_PAGE,
  V5_SIGNUP_PAGE,
  V4_EMAIL_PAGE,
  V3_EMAIL_PAGE,
  V3_SIGNUP_PAGE,
  V2_SIGNUP_PAGE,
  SIGNUP_PAGE,
  SHOPIFY_EMAIL_PAGE,
  ACCEPT_INVITE_PAGE,
} from 'subapps/onboarding/pages'
import { displayConfirmLeave } from 'util/dirtyHolds'
import { selectCurrentUser2faEnforced } from 'ducks/currentUser/selectors/selectCurrentUser2faEnforced'
import { to2faPage } from 'subapps/settings/actions/redirect'
import { isPageAllowedForExpiredBilling } from 'ducks/billing/util'

const isAllowed = (type, token) => {
  if (token) return true
  if (PUBLIC_PAGES.includes(type)) return true
  return false
}

// Auth filtering: onBeforeChange fires before going to a new route, and you
// can redirect if certain conditions aren't met.
export default {
  querySerializer,
  onBeforeChange: (dispatch, getState, { action: { type, payload, meta } }) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const is2faSettingsPage =
      type === to2faPage.type && to2faPage.payload.tab === payload.tab

    if (!isAllowed(type, token)) {
      return dispatch(
        redirect({
          type: LOGIN_PAGE,
          meta,
          payload: {
            returnTo: {
              type,
              payload,
            },
          },
        })
      )
    }

    // If 2fa is enforced and the user hasn't enabled it, redirect to 2fa page
    if (LOGOUT_PAGE !== type && selectCurrentUser2faEnforced(state)) {
      if (is2faSettingsPage) return true
      return dispatch(redirect(to2faPage))
    }

    // If your account or inbox is not active, redirect to billing page
    if (
      !isPageAllowedForExpiredBilling({ type, payload }) &&
      !selectIsBillingAccountActive(state)
    ) {
      return dispatch(redirect({ type: SETTINGS_BILLING_PAGE }))
    }

    // If we are on a signup page, look to see if the layout variant is set and store.
    if (
      [
        SIGNUP_PAGE,
        V2_SIGNUP_PAGE,
        V3_SIGNUP_PAGE,
        V4_EMAIL_PAGE,
        V5_SIGNUP_PAGE,
        V6_SIGNUP_PAGE,
        V6_EMAIL_PAGE,
        SHOPIFY_EMAIL_PAGE,
        ACCEPT_INVITE_PAGE,
      ].includes(type)
    ) {
      // Clean up existing storage
      storage.cleanupAppStorage({ except: [CLICK_ID_KEY] })

      const { current } = meta.location
      const { query: { lv: layoutVariant } = {} } = current

      if (
        (type === SIGNUP_PAGE || type === V2_SIGNUP_PAGE) &&
        layoutVariant === undefined
      ) {
        const finalVariant = 2
        Cookies.set('onboardingLayoutVariant', finalVariant, {
          domain: `.${config.appDomain}`,
          expires: 365,
        })
        storage.set('onboardingLayoutVariant', finalVariant)
      } else if (
        ((type === V5_SIGNUP_PAGE ||
          type === V4_EMAIL_PAGE ||
          type === V3_EMAIL_PAGE ||
          type === V3_SIGNUP_PAGE) &&
          layoutVariant === undefined) ||
        type === SHOPIFY_EMAIL_PAGE ||
        type === ACCEPT_INVITE_PAGE
      ) {
        const finalVariant = 3
        Cookies.set('onboardingLayoutVariant', finalVariant, {
          domain: `.${config.appDomain}`,
          expires: 365,
        })
        storage.set('onboardingLayoutVariant', finalVariant)
      } else if (
        (type === V6_SIGNUP_PAGE || type === V6_EMAIL_PAGE) &&
        layoutVariant === undefined
      ) {
        const finalVariant = 5
        Cookies.set('onboardingLayoutVariant', finalVariant, {
          domain: `.${config.appDomain}`,
          expires: 365,
        })
        storage.set('onboardingLayoutVariant', finalVariant)
      } else if (layoutVariant !== undefined) {
        const finalVariant = parseInt(layoutVariant, 10)
        Cookies.set('onboardingLayoutVariant', finalVariant, {
          domain: `.${config.appDomain}`,
          expires: 365,
        })
        storage.set('onboardingLayoutVariant', finalVariant)
      }
    }

    return true
  },
  displayConfirmLeave,
}
