import { createSelector } from 'reselect'
import { selectIsInInbox } from 'selectors/location'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectIsSettingUpTheCurrentMailBox } from 'ducks/mailboxes/selectors/selectIsSettingUpTheCurrentMailBox'
import { selectCurrentMailboxHasTickets } from 'ducks/mailboxes/selectors/selectCurrentMailboxHasTickets'
import { selectCurrentMailbox } from 'ducks/mailboxes/selectors/selectCurrentMailbox'
import { CHANNEL_STATE, CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import { selectIsInboxBootstrapped } from 'ducks/bootstrap/selectors/selectIsInboxBootstrapped'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectSearchesRequestState } from './search'

export const selectShowContinueForwardingSetupPane = createSelector(
  selectIsInInbox,
  selectCurrentMailbox,
  selectIsInboxBootstrapped,
  selectCurrentMailboxHasTickets,
  (
    isInInbox,
    currentMailbox,
    isInboxBootstrapped,
    currentMailboxHasTickets
  ) => {
    const { state, type: channelType } = currentMailbox || {}

    const isUnconfirmedForwarding =
      state === CHANNEL_STATE.UNCONFIRMED &&
      channelType === CHANNEL_TYPE.FORWARDING

    return (
      isInboxBootstrapped &&
      isInInbox &&
      isUnconfirmedForwarding &&
      currentMailboxHasTickets === false
    )
  }
)

export const selectShowContinueForwardingSetupBar = createSelector(
  selectIsInInbox,
  selectCurrentMailbox,
  selectIsInboxBootstrapped,
  selectSearchesRequestState,
  selectIsSettingUpTheCurrentMailBox,
  selectShowContinueForwardingSetupPane,
  selectPrefersClassicView,
  selectCurrentTicketId,
  (
    isInInbox,
    currentMailbox,
    isInboxBootstrapped,
    searchesState,
    isSettingUpTheCurrentMailBox,
    showContinueForwardingSetupPane,
    isClassicView,
    currentTicketIdFromPage
  ) => {
    const { state, type: channelType } = currentMailbox || {}

    const isUnconfirmedForwarding =
      state === CHANNEL_STATE.UNCONFIRMED &&
      channelType === CHANNEL_TYPE.FORWARDING

    const isSearchesLoaded = searchesState.loaded && !searchesState.error

    return (
      isInboxBootstrapped &&
      isInInbox &&
      isUnconfirmedForwarding &&
      isSearchesLoaded &&
      isClassicView &&
      !currentTicketIdFromPage &&
      !isSettingUpTheCurrentMailBox &&
      !showContinueForwardingSetupPane
    )
  }
)
