import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import {
  _processQueryStringPart,
  _splitQueryString,
  constructSearchQueryObject,
} from 'util/search'
import { emptyArr } from 'util/arrays'
import { selectKnownMailboxes } from 'ducks/mailboxes/selectors/selectKnownMailboxes'
import { selectMailboxesById } from 'ducks/mailboxes/selectors/selectMailboxesById'
import { getMailboxNameOrEmail } from 'util/mailboxes'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectIsOnSearchPage } from 'selectors/location'
import { selectSuggestionList } from 'ducks/searches/selectors/selectSuggestionList'
import { selectTicketSearchOperatorValueMap } from './base'

export const selectTicketSearchQueryObjectByQueryString = createCachedSelector(
  (_state, queryString) => queryString,
  selectTicketSearchOperatorValueMap,
  (queryString, operatorValueMap) => {
    return constructSearchQueryObject(queryString, operatorValueMap)
  }
)((_state, queryString) => queryString || 'unknown')

export const selectCurrentSearchQueryId = createSelector(
  selectIsOnSearchPage,
  selectCurrentQueryId,
  (isOnSearch, queryId) => (isOnSearch ? queryId : '')
)

export const selectFilterRegex = createSelector(
  selectSuggestionList,
  suggestionList => {
    const allQueries = suggestionList.map(suggestion => suggestion.searchQuery)
    // See more examples at getFilterMatchForQuery tests in src/components/ConversationList/Header/Search/__tests__/util.test.js
    const operatorsPattern = `(${allQueries.join('|')})`
    const valuePattern = `\\s*\\S*(?!\\S\\s)`
    const valueInQuotesPattern = `\\s*(")[^"]*\\4`
    return `(${operatorsPattern}(${valueInQuotesPattern}|${valuePattern}))`
  }
)

export const selectCurrentSubmittedTicketSearchFilterMatches = createSelector(
  selectCurrentSearchQueryId,
  selectFilterRegex,
  (submittedSearchQueryString, filterRegex) => {
    if (!submittedSearchQueryString) return []
    return (
      Array.from(
        new Set(submittedSearchQueryString.match(new RegExp(filterRegex, 'g')))
      ) || []
    )
  }
)

export const selectSearchMailboxIds = state =>
  state.search.searchMailboxIds || emptyArr

export const selectIsReloadingSubmittedSearchQuery = state =>
  state.search.isReloadingSubmittedSearchQuery

export const selectSearchEditorState = state => state.search.editorState
export const selectSearchEditor = state => state.search.editor

export const selectHumanizedSearchQueryArray = createCachedSelector(
  selectKnownMailboxes,
  selectMailboxesById,
  (_state, queryString) => queryString,
  (mailboxes, mailboxesById, queryString) => {
    const parts = _splitQueryString(queryString)
    const results = parts
      .map(part => {
        if (!part) return part
        const filter = _processQueryStringPart(part)
        const filterName = `${filter.key}:`
        const value = filter.values[0]
        if (filterName === 'inbox:') {
          const mailbox = mailboxesById[value]
          if (!mailbox) return part
          return [filter.key, getMailboxNameOrEmail(mailbox, mailboxes)].join(
            ':'
          )
        }
        return part
      })
      .filter(Boolean)
    return Array.from(new Set(results))
  }
)((_state, queryString) => queryString || 'unknown')
