/* eslint-disable no-multi-assign */ // ok in reducers
import * as types from 'constants/action_types'
import { INSERT_CANNED_REPLY } from 'ducks/cannedReplies/types'
import * as modals from 'constants/modal_types'
import { createActionTypeReducer } from 'util/reducers'

const defaultState = {
  modalType: null,
  modalProps: {},
}
const reducers = {}

reducers[types.SHOW_MODAL] = (state, action) =>
  Object.assign({}, state, {
    modalType: action.data.modalType,
    modalProps: action.data.modalProps || {},
  })

reducers[types.HIDE_MODAL] = reducers[types.ASSIGN_TICKETS_TO_AGENT] = reducers[
  INSERT_CANNED_REPLY
] = () => defaultState

reducers[types.UPDATE_MODAL_PROPS] = (state, action) =>
  Object.assign({}, state, {
    modalProps: Object.assign({}, state.modalProps, action.data.modalProps),
  })

reducers[types.GROUP_DRILL_DOWN] = (state, action) => {
  const { drilledDownGroupId } = action.data
  const modalProps = Object.assign({}, state.modalProps)
  modalProps.drilledDownGroupId = drilledDownGroupId

  return Object.assign({}, state, { modalProps })
}

reducers[types.ASSIGN_TICKET_TO_AGENT] = (state, action) => {
  const { agentId } = action.data
  const modalProps = Object.assign({}, state.modalProps)
  modalProps.selectedAgentId = agentId

  return Object.assign({}, state, { modalProps })
}

reducers[types.SET_TICKET_ASSIGNMENT_FILTER] = (state, action) => {
  const { term } = action.data
  const modalProps = Object.assign({}, state.modalProps)
  modalProps.ticketAssignmentFilter = term

  return Object.assign({}, state, { modalProps })
}

reducers[types.SET_TICKET_GROUP_ASSIGNMENT_FILTER] = (state, action) => {
  const { term } = action.data
  const modalProps = Object.assign({}, state.modalProps)
  modalProps.ticketGroupAssignmentFilter = term

  return Object.assign({}, state, { modalProps })
}

reducers[types.LIGHTBOX_NEXT] = state => {
  const { imageIndex, totalImages } = state.modalProps
  return {
    ...state,
    modalProps: {
      ...state.modalProps,
      imageIndex: imageIndex + 1 === totalImages ? totalImages : imageIndex + 1,
    },
  }
}

reducers[types.LIGHTBOX_PREVIOUS] = state => {
  const { imageIndex } = state.modalProps
  return {
    ...state,
    modalProps: {
      ...state.modalProps,
      imageIndex: Math.max(0, imageIndex - 1),
    },
  }
}

reducers[types.INTEGRATIONS_INTEGRATION_SELECTED] = (state, action) => {
  return {
    modalType: modals.INTEGRATIONS,
    modalProps: action, // GR: not used?
  }
}

reducers['*'] = (draftState, action) => {
  if (action.modal) {
    const { type, props, open } = action.modal
    if (!open) {
      return defaultState
    }
    Object.assign(draftState, {
      modalType: type,
      modalProps: props,
    })
  }
  return draftState
}

export default createActionTypeReducer(reducers, defaultState)
