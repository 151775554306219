import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectAccount } from 'ducks/accounts/selectors/selectAccount'
import debug from 'util/debug'
import { createBasicSelector } from 'util/redux'

export const selectAccountPreferences = state =>
  selectAccount(state).preferences || emptyObj

const makeSelectAccountPreference = (name, defaultValue = null) => state =>
  selectAccountPreferences(state)[name] || defaultValue

const makeSelectBooleanAccountPreference = name => state =>
  !!selectAccountPreferences(state)[name]

export const selectRawAccountPreferenceReportingPermission = makeSelectAccountPreference(
  'reporting_permission'
)

export const selectAccountPreferenceReportingPermission = createBasicSelector(
  selectRawAccountPreferenceReportingPermission,
  reportingPermission => reportingPermission?.toUpperCase()
)

export const selectAccountChatRoomId = makeSelectAccountPreference(
  'chat_room_id'
)

export const selectAccountCanAccess20Beta = makeSelectBooleanAccountPreference(
  'groove_20_beta'
)

export const selectAccountPrefersUnsafeCustomProfile = makeSelectBooleanAccountPreference(
  'custom_profile_unsafe'
)

export const selectAccountPrefersHistorical = makeSelectAccountPreference(
  'historical_reports'
)

export const selectAccountPreferredReportsAverage = makeSelectAccountPreference(
  'reports_average'
)

export const selectAccountCanSeePinnedSearches = makeSelectAccountPreference(
  'show_pinned_searches'
)

export const selectAccountPrefersScopedContactsSearch = makeSelectBooleanAccountPreference(
  'scoped_contacts_search'
)

export const selectShowExperimentalRealtimeWarnings = makeSelectBooleanAccountPreference(
  'experimental_realtime_warnings'
)

export const selectShowSelfHelpCancellation = makeSelectBooleanAccountPreference(
  'self_help_cancellation'
)

export const selectCanMentionAnyAgent = makeSelectBooleanAccountPreference(
  'mention_any_agent'
)

export const selectShouldPreloadTickets = makeSelectBooleanAccountPreference(
  'ticket_preloading'
)

export const selectAccountPreferenceCustomProfileAppInstalled = makeSelectAccountPreference(
  'custom_profile_app_installed',
  false
)

export const selectAccountPreferenceCrmSidebar = makeSelectAccountPreference(
  'crm_sidebar',
  true
)

export const selectAccountPreferenceDebugFrontend = makeSelectAccountPreference(
  'debug_frontend',
  false
)

export const selectAccountPreferenceChatEnabled = makeSelectAccountPreference(
  'chat_enabled',
  false
)

export const selectAccountPreferenceSortByCollaboratorCommentEnabled = makeSelectAccountPreference(
  'sort_conversation_by_collaborator_comment_enabled',
  false
)

export const selectAccountPreferenceShowLegacyIntegrationsPage = makeSelectAccountPreference(
  'show_legacy_integrations_page',
  false
)

export const selectPrefersEditMailboxViewSettings = makeSelectAccountPreference(
  'edit_mailbox_view_settings',
  false
)

export const selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled = makeSelectAccountPreference(
  'sort_conversation_by_last_unanswered_user_message_at_enabled',
  false
)

export const selectAccountPreferenceOutboundEmailBrandingDisabled = makeSelectAccountPreference(
  'outbound_email_branding_disabled',
  false
)

export const selectPrefersEnabledFeatures = makeSelectAccountPreference(
  'enabled_features',
  ''
)

export const selectPrefersDisabledFeatures = makeSelectAccountPreference(
  'disabled_features',
  ''
)

export const selectAccountPreferenceAiInstantReplies = makeSelectAccountPreference(
  'ai_instant_replies',
  false
)

export const selectEnabledFeatures = createSelector(
  selectPrefersEnabledFeatures,
  features =>
    features
      .split(',')
      .map(feature => feature.trim())
      .filter(feature => !!feature)
)

export const selectDisabledFeatures = createSelector(
  selectPrefersDisabledFeatures,
  features =>
    features
      .split(',')
      .map(feature => feature.trim())
      .filter(feature => !!feature)
)

export const selectIsFeatureEnabled = createBasicSelector(
  selectEnabledFeatures,
  (_state, feature) => feature,
  (features, feature) => features.includes(feature)
)

export const selectIsFeatureDisabled = createBasicSelector(
  selectDisabledFeatures,
  (_state, feature) => feature,
  (features, feature) => features.includes(feature)
)

// Social is no longer hidden behind a feature flag, so for now we
// just create a alias. Leave this function here incase we want to introduce
// additional rules in the future
export const selectIsSocialEnabled = selectAccountPreferenceChatEnabled

export const selectAccountPrefersReplyOpen = createSelector(
  makeSelectAccountPreference('reply_button'),
  replyButtonState => replyButtonState === 'opened'
)

export const selectPrefersInboxBillingVersion = makeSelectAccountPreference(
  'inbox_billing_version',
  ''
)

export const selectPrefersKbBillingVersion = makeSelectAccountPreference(
  'kb_billing_version',
  ''
)

export const selectPrefersAddonBillingVersion = makeSelectAccountPreference(
  'addon_billing_version',
  ''
)

export const selectPrefersBillingCoupon = makeSelectAccountPreference(
  'billing_coupon',
  null
)

export const selectPrefersExcludeImmediatelyClosedConversationsInReports = state =>
  selectIsFeatureEnabled(
    state,
    'exclude_immediately_closed_conversations_in_reports'
  )

export const selectPrefers2FAEnforced = makeSelectAccountPreference(
  'two_factor_authentication_globally_enforced',
  false
)

export const selectCanSendDebugInfo = createSelector(
  selectAccountPreferenceDebugFrontend,
  canDebugFrontend => {
    return canDebugFrontend || debug.enabled
  }
)

export const selectPrefersAiEnabled = makeSelectAccountPreference('ai_enabled')
export const selectPrefersKbEnabled = makeSelectAccountPreference('kb_enabled')
export const selectPrefersReportCachingEnabled = makeSelectAccountPreference(
  'report_caching_enabled'
)
