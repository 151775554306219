import { useCallback, useEffect, useState } from 'react'
import uuid from 'util/uuid'

export default function useArrayOfStringsState({
  initialItems = [],
  maxItems,
  onChange,
  onCountChange,
}) {
  const mappedInitialItems = initialItems
    .filter(x => !!x && x !== '')
    .map((item, index) => ({ ...item, isPrimary: index === 0, index }))
  const [items, doSetItemsBase] = useState(mappedInitialItems)
  const doSetItems = useCallback(newValueOrFunc => {
    doSetItemsBase(existingItems => {
      const newItems =
        typeof newValueOrFunc === 'function'
          ? newValueOrFunc(existingItems)
          : newValueOrFunc
      return newItems
    })
  }, [])
  useEffect(() => onChange && onChange(items), [onChange, items])
  useEffect(() => onCountChange && onCountChange(items.length), [
    items.length,
    onCountChange,
  ])
  return {
    doAdd: useCallback(
      () => {
        doSetItems(existingItems => {
          if (existingItems.length < maxItems) {
            return [...existingItems, { id: uuid(), value: '' }]
          }
          return existingItems
        })
      },
      [doSetItems, maxItems]
    ),

    doRemove: useCallback(
      i => {
        doSetItems(existingItems => {
          const { isPrimary: wasPrimary } = existingItems[i]
          const newItems = [...existingItems]
          newItems.splice(i, 1)
          if (newItems.length === 0) {
            return [{ isPrimary: true, index: 0, value: '' }]
          }
          if (wasPrimary) {
            const newPrimaryI =
              i + 1 === existingItems.length ? newItems.length - 1 : i
            newItems[newPrimaryI] = {
              ...newItems[newPrimaryI],
              isPrimary: true,
            }
          }
          return newItems.map((x, index) => ({ ...x, index }))
        })
      },
      [doSetItems]
    ),

    doReorderItems: useCallback(
      ({ oldIndex, newIndex }) => {
        const newItems = [...items]
        const item = newItems.splice(oldIndex, 1)[0]
        newItems.splice(newIndex, 0, item)
        doSetItems(newItems.map((x, index) => ({ ...x, index })))
      },
      [doSetItems, items]
    ),

    doSetRecord: useCallback(
      (index, value) => {
        doSetItems(existingItems => {
          const newItems = [...existingItems]
          newItems[index] = { ...newItems[index], index, value }
          return newItems
        })
      },
      [doSetItems]
    ),

    doSetPrimary: useCallback(
      index => {
        doSetItems(existingItems => {
          const newItems = [...existingItems]
          const currentPrimaryI = existingItems.findIndex(
            ({ isPrimary }) => isPrimary
          )
          newItems[currentPrimaryI] = {
            ...newItems[currentPrimaryI],
            isPrimary: false,
          }
          newItems[index] = {
            ...newItems[index],
            index,
            isPrimary: true,
          }
          return newItems
        })
      },
      [doSetItems]
    ),
    items,
  }
}
