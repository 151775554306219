import { createSelector } from 'reselect'

import { objectHashSerializer, memoize } from 'util/memoization'
import { uniqByProp } from 'util/arrays'

import selectAccountIntegrations from 'ducks/integrations/selectors/selectAccountIntegrations'
import { selectEmailMarketingIntegrations } from 'ducks/integrations/emailMarketing/selectors'
import selectIntegrationCredentials from 'ducks/integrations/selectors/selectIntegrationCredentials'
import { emptyObj } from 'util/objects'

const selectSidebarCards = state => state.sidebarCards || emptyObj

export function selectStripeCustomerPageUrl(state, id) {
  const cards = selectSidebarCards(state)
  if (!cards[id] || !cards[id].data) return null
  return cards[id].data.customerPageUrl
}

export function selectStripeSubscriptionPageUrl(state, id) {
  const cards = selectSidebarCards(state)
  if (!cards[id] || !cards[id].data) return null
  return cards[id].data.subscriptionPageUrls
}

const INTEGRATION_NAMES = {
  JIRA_CLOUD: 'Jira Cloud',
  GITHUB: 'GitHub',
  HUBSPOT: 'HubSpot',
  JIRA_SERVER: 'Jira Server',
  SALESFORCE: 'Salesforce',
  SHOPIFY: 'Shopify',
  STRIPE: 'Stripe',
  TRELLO: 'Trello',
}
export const selectSidebarCardIntegrations = createSelector(
  selectIntegrationCredentials,
  selectAccountIntegrations,
  selectEmailMarketingIntegrations,
  (userIntegrations, accountIntegrations, emailMarketingIntegrations) => {
    /**
     * (pnagy) A bit hackish here. We use the `selectAccountIntegrations` coming from
     * the integration duck and that selectors will give us all the integrations with
     * `kind: "account"` including the email marketing ones too. But because those
     * integrations are coming from legacy they have a different structure and they're
     * handled differently. Long story short the order counts in the line below, since we
     * want to make sure that the email marketing integration objects are the ones from
     * their own selector.
     */
    return uniqByProp(
      [
        ...(userIntegrations || []),
        ...(emailMarketingIntegrations || []),
        ...(accountIntegrations || []),
      ],
      'id'
    ).map(integration => ({
      name: integration.storeName || INTEGRATION_NAMES[integration.provider],
      ...integration,
    }))
  }
)

export const selectSidebarCardOpenState = createSelector(
  selectSidebarCards,
  cards => id => cards[id] && cards[id].isOpen
)

export const selectIsSidebarCardLoading = createSelector(
  selectSidebarCards,
  cards => id => cards[id] && cards[id].isLoading
)

export const selectIsSidebarCardFailed = createSelector(
  selectSidebarCards,
  cards => id => cards[id] && cards[id].isError
)

export const selectIsSidebarCardExpired = createSelector(
  selectSidebarCards,
  cards => id => cards[id] && cards[id].isExpired
)

const getCard = memoize(cards => memoize(id => cards[id] && cards[id].data), {
  serializer: objectHashSerializer,
})

export const selectSidebarCardData = createSelector(selectSidebarCards, getCard)
