import { emptyObj, isDeepEqual } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createSelector } from 'reselect'
import { selectUnreadByChannelType } from 'ducks/searches/selectors'
import { selectIsInLiveChat, selectIsInSocialChat } from 'selectors/location'

export function selectBase(state) {
  return state.realtime || emptyObj
}

export const selectUnreadRoomIds = createSelector(
  selectBase,
  realtime => realtime.unreadRoomIds || emptyArr
)

let lastIsUnread = null
export const selectIsUnread = createSelector(
  selectBase,
  selectUnreadByChannelType,
  selectIsInLiveChat,
  selectIsInSocialChat,
  (realtime, unreadByChannelType, isInLiveChat, isInSocialChat) => {
    const newIsUnread = {
      widget: isInLiveChat
        ? unreadByChannelType.widget
        : realtime.unreadChannelTypes?.widget,
      social: isInSocialChat
        ? unreadByChannelType.social
        : realtime.unreadChannelTypes?.social,
    }
    if (!isDeepEqual(newIsUnread, lastIsUnread)) {
      lastIsUnread = newIsUnread
    }
    return lastIsUnread
  }
)
