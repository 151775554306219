import { selectContactsById } from 'ducks/crm/contacts'
import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'

export const selectPreviousUserSearchResults = state =>
  state.users.previousSearchResults || emptyObj

export const selectLastUserSearchTerm = state => state.users.lastSearchTerm

export const selectLastUserSearchResults = createSelector(
  selectPreviousUserSearchResults,
  selectLastUserSearchTerm,
  (users, term) => users[term]
)

export const selectSuggestedUsers = state =>
  state.users.suggestedUsers || emptyArr

export const selectSuggestedUsersContactIds = createSelector(
  selectSuggestedUsers,
  users => {
    return users.map(user => user.contact_id).filter(cid => !!cid)
  }
)

export const selectSuggestedContacts = createSelector(
  selectSuggestedUsersContactIds,
  selectContactsById,
  (contactIds, contactsById) => {
    const suggestedContacts = contactIds
      .map(id => contactsById[id])
      .filter(c => !!c)
    return suggestedContacts
  }
)
