import styled from '@emotion/styled'
import { sortableContainer } from 'react-sortable-hoc'

import { Label, Tooltip } from 'shared/ui'
import { Icon } from 'util/ui'

import ArrayOfStringsItem from './ArrayOfStringsItem'
import useArrayOfStringsState from './useArrayOfStringsState'

export default styled(ArrayOfStrings)`
  .RemoveButton {
    width: 43px;
  }

  .link {
    color: ${props => props.theme.color.primary.brand};
    cursor: pointer;
  }
`
const SortableContainer = sortableContainer('div')

function ArrayOfStrings({
  className,
  maxItems = Infinity,
  noun = 'item',
  name,
  numberedKeys,
  primary = false,
  primaryName,
  onChange,
  onCountChange,
  items,
  initialItems = [],
  secondaryNamePrefix,
  sortable = false,
  title,
}) {
  const {
    doAdd,
    doRemove,
    doReorderItems,
    doSetRecord,
    doSetPrimary,
    items: managedItems,
  } = useArrayOfStringsState({
    initialItems: items || initialItems,
    items,
    maxItems,
    onChange,
    onCountChange,
  })

  let primaryRendered = false
  const Wrapper = sortable ? SortableContainer : 'div'
  const wrapperProps = sortable
    ? {
        helperClass: 'dragging',
        onSortEnd: doReorderItems,
        useDragHandle: true,
      }
    : {}
  const currentItems = items || managedItems || []
  return (
    <Wrapper className={className} {...wrapperProps}>
      <Label as="div">
        {`${title} `}
        {primary && (
          <Tooltip
            className="tooltipTrigger"
            as="span"
            position="right"
            tooltip={`The primary ${noun} is selected by clicking the radio button.`}
          >
            <Icon name="question circle outline" />
          </Tooltip>
        )}
      </Label>
      {currentItems.map(({ isPrimary, value }, index) => {
        if (isPrimary) primaryRendered = true
        const numberedName = isPrimary
          ? primaryName
          : `${secondaryNamePrefix}${primaryRendered ? index : index + 1}`
        const computedName = numberedKeys ? numberedName : `${name}[]`
        return (
          <ArrayOfStringsItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            doSetRecord={doSetRecord}
            doSetPrimary={doSetPrimary}
            doRemove={currentItems.length > 1 || value !== '' ? doRemove : null}
            index={index}
            primary={primary}
            isPrimary={isPrimary}
            primaryName={primaryName}
            name={computedName}
            noun={noun}
            secondaryNamePrefix={secondaryNamePrefix}
            sortable={sortable}
            value={value}
          />
        )
      })}
      {numberedKeys &&
        new Array(Math.min(maxItems - 1, maxItems - currentItems.length))
          .fill(null)
          .map((value, index) => {
            return (
              <input
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                type="hidden"
                value=""
                name={`${secondaryNamePrefix}${currentItems.length +
                  index}.content`}
              />
            )
          })}
      {!items ||
        (items.length < maxItems && (
          <div className="link" onClick={doAdd}>
            + Add {noun}
          </div>
        ))}
    </Wrapper>
  )
}
