import { createSelector } from 'reselect'

import { selectHost, selectIsInChat } from 'selectors/location'
import selectCurrentConversationId from 'ducks/searches/selectors/selectCurrentConversationId'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { buildId } from 'util/globalId'
import { selectCurrentTicketUrl } from './selectCurrentTicketUrl'

export const selectCurrentTicketGqlId = createSelector(
  selectCurrentTicketId,
  ticketId => buildId('Conversation', ticketId)
)

export const selectCurrentRoomUrl = createSelector(
  selectCurrentConversationId,
  selectHost,
  (conversationId, host) =>
    `https://${host}/chats?conversationId=${conversationId}`
)

export const selectCurrentConversationUrl = createSelector(
  selectIsInChat,
  selectCurrentTicketUrl,
  selectCurrentRoomUrl,
  (isInChat, currentTicketUrl, currentRoomUrl) =>
    isInChat ? currentRoomUrl : currentTicketUrl
)
