/* eslint-disable no-param-reassign */
import { UPDATE_APP_DATA } from 'constants/action_types'
import { createActionTypeReducer } from 'util/reducers'
import { updateCount as updateFaviconCount } from 'util/favicon'
import {
  ROOMS_REALTIME_SYNC_UNREAD,
  ROOM_OPTIMISTIC_COMPLETE,
} from 'ducks/chat/actionTypes/rooms'
import { SEARCH_SYNC } from 'ducks/searches/actionTypes'
import { FETCH_CURRENT_USER_SUCCESS } from 'ducks/currentUser/types'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'

const initialState = {
  unreadRoomIds: [],
  unreadChannelTypes: {},
  pendingUnreadRoomIds: [],
}

const hasUnreadChannelType = unreadChannelTypes => {
  if (!unreadChannelTypes) return undefined
  return Object.values(unreadChannelTypes).some(e => !!e)
}

const updateFaviconCountWithUnreadChannelTypes = unreadChannelTypes => {
  updateFaviconCount(hasUnreadChannelType(unreadChannelTypes) ? 1 : 0)
}

export default createActionTypeReducer(
  {
    [ROOMS_REALTIME_SYNC_UNREAD]: (draftState, action) => {
      const {
        payload: { unread, currentUser, isInChat },
      } = action

      const unreadChannelTypes = unread[`ag_${currentUser?.id}`]
      const agentUnread = hasUnreadChannelType(unreadChannelTypes)
      if (currentUser && !isInChat && agentUnread !== undefined) {
        draftState.unreadChannelTypes = unreadChannelTypes
        updateFaviconCountWithUnreadChannelTypes(draftState.unreadChannelTypes)
      }
    },
    [SEARCH_SYNC]: (draftState, action) => {
      const {
        payload: {
          currentUser,
          unread,
          isInChat,
          searches,
          currentConversationId,
          pageChannelType,
        },
      } = action
      if (pageChannelType === MAILBOX_CHANNEL_TYPE) return

      const unreadChannelTypes = unread[`ag_${currentUser?.id}`]
      const agentUnread = hasUnreadChannelType(unreadChannelTypes)
      if (agentUnread === undefined) return

      const hasNonCurrentConversationChange = Object.keys(searches)
        .filter(f => f.includes('folderunread'))
        .some(sk => {
          const search = searches[sk]
          const hasPlusChanges =
            search.plus.filter(
              conversationId => conversationId !== currentConversationId
            ).length > 0
          const hasEqualChanges = search.equal.length > 0
          const hasMinusChanges = search.minus.length > 0
          return hasPlusChanges || hasEqualChanges || hasMinusChanges
        })

      if (currentUser && isInChat && hasNonCurrentConversationChange) {
        draftState.unreadChannelTypes = unreadChannelTypes
        updateFaviconCountWithUnreadChannelTypes(draftState.unreadChannelTypes)
      }
    },
    [FETCH_CURRENT_USER_SUCCESS]: (draftState, action) => {
      const {
        data: {
          currentUser: { unreadChannelTypes },
        },
      } = action
      draftState.unreadChannelTypes = unreadChannelTypes
      updateFaviconCountWithUnreadChannelTypes(draftState.unreadChannelTypes)
    },
    [UPDATE_APP_DATA]: (draftState, action) => {
      const {
        data: {
          currentUser: { unreadChannelTypes },
        },
      } = action
      draftState.unreadChannelTypes = unreadChannelTypes
      updateFaviconCountWithUnreadChannelTypes(draftState.unreadChannelTypes)
    },
    [ROOM_OPTIMISTIC_COMPLETE]: (draftState, action) => {
      const {
        payload: { unreadChannelTypes },
      } = action
      Object.assign(draftState.unreadChannelTypes, unreadChannelTypes)

      updateFaviconCountWithUnreadChannelTypes(draftState.unreadChannelTypes)
    },
  },
  initialState
)
