import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { selectMailbox } from 'ducks/mailboxes/selectors/selectMailbox'
import { selectCurrentConversationsById } from 'ducks/tickets/selectors'
import { selectCurrentChannelsById } from 'ducks/channels/selectors'
import { CHANNEL_TYPE } from 'ducks/mailboxes/constants'
import { selectCurrentConversation } from '../../ducks/tickets/selectors/selectCurrentConversation'

export const selectCurrentTicketMailbox = createSelector(
  selectCurrentConversation,
  selectCurrentChannelsById,
  (ticket, channels) => {
    if (!ticket || !channels) return null
    return channels[ticket.channel]
  }
)

export const selectHasMailboxReceivedForwardingConfirmationCode = createCachedSelector(
  selectCurrentConversationsById,
  selectMailbox,
  (_state, mailboxId) => mailboxId,
  (ticketsById, mailbox, mailboxId) => {
    if (!mailbox || mailbox.type !== CHANNEL_TYPE.FORWARDING) return false
    return Object.values(ticketsById).some(
      ticket =>
        ticket.channel === mailboxId &&
        (ticket.summaryMessage?.bodyPlainText?.match(/confirmation\Wcode/i) ||
          ticket.subject?.match(/forwarding\Wconfirmation/i))
    )
  }
)((_state, mailboxId) => mailboxId || 'unknown')
