import { css } from '@emotion/react'
import { useCallback } from 'react'
import { sortableElement, sortableHandle } from 'react-sortable-hoc'
import { draggableDots as Dots } from 'assets/icons/groove/v2'
import { Tooltip } from 'shared/ui'
import { Button, Icon, Input, Radio } from 'util/ui'

const handleCss = css`
  font-size: 19px;
  margin: -5px -3px;
  cursor: grab;
  .dragging & {
    cursor: grabbing;
    pointer-events: all !important;
  }
`
const rowCss = css`
  display: flex;
  z-index: 100000;
  margin-bottom: 8px;
  .fluid {
    flex-grow: 1;
  }

  &&& .RemoveButton {
    margin-left: 8px;
    line-height: 30px;
    width: 43px;
  }
`

const SortableElement = sortableElement('div')
const DragHandle = sortableHandle(() => <Dots css={handleCss} />)

export default function ArrayOfStringsItem({
  doSetRecord,
  doSetPrimary,
  doRemove,
  index,
  isPrimary,
  primary,
  noun,
  name,
  sortable,
  value,
}) {
  const onCheckbox = useCallback(() => doSetPrimary(index), [
    index,
    doSetPrimary,
  ])
  const onChange = useCallback(
    ({ target: { value: newValue = '' } = {} }) => {
      doSetRecord(index, newValue)
    },
    [index, doSetRecord]
  )
  const onRemove = useCallback(
    e => {
      e.preventDefault()
      if (doRemove) doRemove(index)
    },
    [index, doRemove]
  )

  const Wrapper = sortable ? SortableElement : 'div'
  const wrapperProps = sortable
    ? {
        collection: 'ArrayOfStrings',
        index,
        key: index,
      }
    : {}
  return (
    <Wrapper css={rowCss} className={'sui'} {...wrapperProps}>
      <Input
        fluid
        label={
          (primary || sortable) && {
            basic: true,
            content: [
              primary && (
                <Tooltip
                  key="primary"
                  position="left"
                  tooltip={`Select as primary ${noun}`}
                >
                  <Radio checked={isPrimary} onClick={onCheckbox} />
                </Tooltip>
              ),
              sortable && <DragHandle key="dragHandle" />,
            ],
          }
        }
        name={`${name}.content`}
        onChange={onChange}
        value={value || ''}
      />
      <Button
        as="div"
        className="RemoveButton"
        disabled={!doRemove}
        basic
        icon
        onClick={onRemove}
      >
        <Icon name="trash alternate outline" />
      </Button>
    </Wrapper>
  )
}
